import React from "react"
import Navbar from "../Components/Navbar"
import Footer from "../Components/Footer"

const PrivacyPolicyScreen = () => {
	return (
		<div className='container-fluid p-0'>
			<div className='ps-2 pe-2'>
				<Navbar />
			</div>
			<div className='container mb-lg-5 pb-lg-5'>
				<hr className='d-lg-none' />

				<p className=' font-mon warranty-border-heading  d-lg-none mt-4 pt-lg-3'>
					PRIVACY POLICY
				</p>
				<hr className='d-lg-none' />
				<div className='margin-top-5 d-none d-lg-block'>
					<hr className='d-lg-none' />
					<div className='d-flex down-border pb-4 '>
						<div className='col-8'>
							<p className=' font-mon mb-lg-4 border-heading pt-lg-3'>
								PRIVACY POLICY
							</p>
						</div>
					</div>
				</div>
				<ol>
					<li className='h5 mt-4 fw-bold '>
						Introduction:
					</li>
					<ul>
						<li className='mt-2'>
							Emotorad.com , a private limited
							company having CIN Number
							U74999WB2016PTC218774 and
							incorporated under Companies Act,
							2013 (Hereinafter “Company”). We
							respect your privacy and are
							committed to make sure that there is
							complete compliance from our part
							with the data privacy laws of India.
							This Privacy Policy has been drafted
							keeping in mind the same and shall
							primarily pertain to answering the
							following questions of Users: What
							is the Data collected from Users
							when using our Website? What are the
							various methods by which we collect
							Data from Users? What is the
							security provided for such Data
							collected from Users? We urge all
							Users and individuals who are to
							visit, use or gain access to our
							Services and are in correspondence
							with us via email, messaging service
							and / or any other forms of
							electronic communication to go
							through this Privacy Policy so as to
							be reasonably informed regarding all
							questions as specified above; In the
							event there is any change,
							modification, deletions and / or
							deletions, we shall inform the Users
							and individuals so associated with
							us in the manner as prescribed under
							this Privacy Policy.
						</li>

						<ol>
							<li className='mt-2'>
								What is the Data collected from
								Users when using our Website?
							</li>
							<li className='mt-2'>
								What are the various methods by
								which we collect Data from Users?
							</li>
							<li className='mt-2'>
								What is the security provided for
								such Data collected from Users?
							</li>
						</ol>
						<li className='mt-2'>
							We urge all Users and individuals
							who are to visit, use or gain access
							to our Services and are in
							correspondence with us via email,
							messaging service and / or any other
							forms of electronic communication to
							go through this Privacy Policy so as
							to be reasonably informed regarding
							all questions as specified above;
						</li>
						<li className='mt-2'>
							In the event there is any change,
							modification, deletions and / or
							deletions, we shall inform the Users
							and individuals so associated with
							us in the manner as prescribed under
							this Privacy Policy.
						</li>
					</ul>
					<li className='h5 mt-4 fw-bold '>
						Definitions:
					</li>
					<p>
						It is important to note that the terms
						used in the Privacy Policy, unless
						specified as otherwise, shall mean and
						be construed in the same manner
						(including but not limited to) as
						under the Laws of india. The following
						terms are essential to be defined for
						the purposes of this Privacy Policy
						and hence must be carefully read and
						understood:
					</p>
					<ul>
						<li>ACT:</li>
						<p>
							Unless specified otherwise, shall
							mean and be construed as referring
							to the Information Technology Act,
							2000 and all of its amendments
							therein;
						</p>

						<li>DATA:</li>
						<p>
							A representation of information,
							knowledge, facts, concepts or
							instructions which are being
							prepared or have been prepared in a
							formalised manner, and is intended
							to be processed, is being processed
							or has been processed in a computer
							system or computer network, and may
							be in any form (including computer
							printouts magnetic or optical
							storage media, punched cards,
							punched tapes) or stored internally
							in the memory of the computer;
						</p>

						<li>DEVICE:</li>
						<p>
							Any computer, mobile or other
							technology so used for the gaining
							access to Our Website by the User;
						</p>

						<li>GRIEVANCE OFFICER:</li>
						<p>
							An individual who shall be in charge
							of dealing with any and all privacy
							concerns that arise from the User(s)
							in manner as specified under this
							Privacy Policy and under Information
							Technology (Reasonable security
							practices and procedures and
							sensitive personal data or
							information) Rules, 2011;
						</p>

						<li>INFORMATION:</li>
						<p>
							Includes data, message, text,
							images, sound, voice, codes,
							computer programmes, software and
							data bases or micro film or
							computer-generated micro fiche;
						</p>

						<li>LAW:</li>
						<p>
							Act of Parliament or of a State
							Legislature, Ordinances promulgated
							by the President or a Governor, as
							the case may be, Regulations made by
							the President under Article 240,
							Bills enacted as President's Act
							under sub-clause (a) of clause (1)
							of Article 357 of the Constitution
							and includes rules, regulations,
							bye-laws and orders issued or made
							thereunder;
						</p>

						<li>PASSWORD:</li>
						<p>
							A secret word, phrase, code,
							passphrase, secret key, encryption
							or decryption keys that one uses to
							gain admittance or access to
							information;
						</p>
					</ul>
					<li className='h5 mt-4 fw-bold '>
						PERSONAL IDENTIFIABLE INFORMATION
						(PII):
					</li>
					<p>
						Information by which User(s) can be
						recognised, contacted and identified
						such as:
					</p>
					<ol>
						<li>His/her name;</li>
						<li>
							Address (Residential or otherwise);
						</li>
						<li>Occupation;</li>
						<li>Email address;</li>
						<li>Telephone number;</li>
						<li>Education;</li>
						<li>
							Internet Service Provider details;
						</li>
						<li>
							Aadhar Card / PAN Card / Driver’s
							Licence / Voters’ ID.
						</li>
					</ol>
					<li className='h5 mt-4 fw-bold '>
						SENSITIVE PERSONAL DATA OR INFORMATION
						(SPDI):
					</li>
					<p>
						Sensitive personal data or information
						of a person means such personal
						information which consists of
						information relating to;—
					</p>
					<ol>
						<li>Password;</li>
						<li>
							Any information so provided
							regarding the sexual orientation of
							the User(s);
						</li>
						<li>
							Any information regarding financial
							information of the User(s).
						</li>
					</ol>
					<p>
						Provided that, any information that is
						freely available or accessible in
						public domain or furnished under the
						Right to Laws of india or any other
						law for the time being in force shall
						not be regarded as sensitive personal
						data or information for the purposes
						of these rules.
					</p>

					<li className='h5 mt-4 fw-bold '>
						Data Collected and Purpose:
					</li>
					<p>
						That the Company shall use, collect
						and retain Data and Information of the
						User(s) and including Personal
						Identifiable Information and Sensitive
						Personal Information for the following
						purposes:
					</p>
					<ol>
						<li>
							In order to identify the User(s) and
							provide the complete services of the
							Website to its fullest potential in
							order to maximise the benefit of the
							User(s);
						</li>
						<li>
							In order to handle orders, deliver
							products, process payments and
							communicate further promotional
							activities and offers to the
							User(s);
						</li>
						<li>
							In order to keep records, data,
							information and statistics to chart
							the User’s development while using
							the Website and further
							modifications if any required for
							the purposes of betterment of User –
							Website relations;
						</li>
						<li>
							In order to keep the User(s)
							regularly updated on any and all
							notifications, reports,
							modifications, developments
							regarding the Website and / or the
							Company;
						</li>
						<li>
							In order to report any and all
							policy changes, newsletters,
							promotional activities and / or
							service offerings that are either
							provided or going to be provided in
							the near future by the Company via
							the Website or any other platform;
						</li>
						<li>
							In order to enhance the security and
							safety of services do provided by
							the Website and the Company to the
							User(s);
						</li>
						<li>
							Any and all lawful purposes related
							to and arising from the working of
							the Website and the Company and / or
							in furtherance of the same.
						</li>
						<p>
							That the Company shall notify the
							User(s) of any and all changes
							regarding the purpose of the
							collection, storage and retention of
							above Data and Information.
						</p>
					</ol>
					<li className='h5 mt-4 fw-bold '>
						Third Party Use:
					</li>
					<p>
						We use the following third party
						Websites, plug-ins and general
						software for the purposes so specified
						above and enhance the working,
						performance and personalization of the
						Website:
					</p>
					<ol>
						<li>Cookies:</li>
						<p>
							Cookies are small text based files
							placed on the User’s device for the
							purposes of enhancing
							personalisation of the Website. We
							use cookies not to store your
							personal information but rather to
							ease access to the Website and
							smoothen out use of the same over a
							course of time. In the event the
							User(s) is unhappy with the storing
							of cookies, they may remove the same
							from their devise via cleaning
							storage and selecting the cookies
							that they are comfortable being
							placed on their Device;
						</p>
						<li>Web Beacons:</li>
						<p>
							Web Beacons are often transparent
							graphic images that are placed in
							order to monitor the behaviour of
							the User(s) visiting and using the
							Website and is often used in
							combination with cookies.
						</p>
						<li>Google Analytics:</li>
						<p>
							Google Analytics is a web based
							Website that offers tools and
							services for the purposes of
							analysis of data and make more the
							Website’s performance better.
						</p>
						<li>Third Party Advertisers:</li>
						<p>
							We do host and partake in the
							process of hosting certain
							third-party advertisements and
							links. The User must note however
							that such third parties are in no
							manner associated with the Website
							or the Company and that the User(s)
							must click on such advertisements /
							links on their own accord, risk
							and/or understanding.
						</p>
					</ol>

					<li className='h5 mt-4 fw-bold '>
						Rights of User:
					</li>
					<p>
						That under this Privacy Policy the
						User(s) shall have the following
						rights regarding their Data and
						information, namely:
					</p>
					<ol>
						<li className='mt-2'>
							The User(s) shall have the right to
							access the Data and Information so
							stored by the Website and Company
							during the period of use of the
							Website by the User;
						</li>
						<li className='mt-2'>
							The User(s) shall have the right to
							refuse non –essential cookies and
							web beacons on opening the website.
							The User(s) shall be clearly
							explained the exact purpose of each
							and every cookie / web beacon so
							utilized by the Company;
						</li>
						<li className='mt-2'>
							The User shall have the right to
							delete, erase, clear and pause any
							and all forms and methods of data
							and information collection, use,
							retention and storage by the Website
							and/or the Company. The User shall
							send a request via mail to the
							Grievance Officer requesting such
							deletion of Data and Information;
						</li>
						<li className='mt-2'>
							{" "}
							User shall have the right to raise
							any and all grievances regarding
							use, storage and retention of data
							and information by the Company. Such
							Grievances shall be raised by
							sending an email to the Grievance
							Officer so appointed by the Company
							for the purposes of answering any
							and all questions, doubts,
							clarifications and pointers
							regarding the Privacy Policy of the
							Services.i
						</li>
					</ol>
					<li className='h5 mt-4 fw-bold '>
						Financial Data and Information:
					</li>
					<ol>
						<li>
							That the Website shall store and
							retain only the transaction details
							of the User which are necessary for
							the purposes of proper functioning
							of the Website, maintenance of
							records of User, correspondence and
							authentication of purchases so made
							on the Website.
						</li>
						<li>
							Apart from the above specified Data
							and Information, all financial
							information is stored by agreed
							Payment Processor and you are
							encouraged to contact them directly
							for responses to your questions
							related to the finances. Following
							are their contact details:
						</li>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							FullName:
						</p>
						<h4 className='fw-bold'>
							Arjun khatri
						</h4>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Email ID:
						</p>
						<h4 className='fw-bold'>
							arjun@emotorad.com
						</h4>

						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Office Number:
						</p>
						<h4 className='fw-bold'>
							+91 8686050590
						</h4>

						<hr />
					</ol>
					<li className='h5 mt-4 fw-bold '>
						Data Security and Grievance Officer:
					</li>
					<ol>
						<li>
							We use software, hardware and
							technical based security measures to
							help protect your Data. While we
							have taken reasonable steps to
							secure the Data you provide to us,
							please be aware that despite our
							efforts, no security measures are
							perfect or impenetrable, and no
							method of data transmission can be
							guaranteed against any interception
							or other type of misuse. We use the
							information security management
							system as specified under ISO/IEC
							27001.
						</li>
						<li>
							In the event there is any complaint
							/ clarification / doubt that the
							User so seeks to be clarified, they
							must contact the Grievance Officer
							as stipulated under Laws of india.
							Following are the details of the
							Grievance Officer:
						</li>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							FullName:
						</p>
						<h4 className='fw-bold'>
							Arjun khatri
						</h4>
						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Email ID:
						</p>
						<h4 className='fw-bold'>
							arjun@emotorad.com
						</h4>

						<hr />
						<p
							className='mb-0 text-muted mt-3'
							style={{ fontSize: "0.8em" }}>
							Office Number:
						</p>
						<h4 className='fw-bold'>
							+91 8686050590
						</h4>

						<hr />
						<li>
							The Grievance Officer shall attempt
							to resolve any and all issues so
							brought before him /her in a fair
							and expeditious manner within 28
							business days. After completion of
							the review the Grievance Officer
							shall submit a report to the User
							and the Company.
						</li>
					</ol>
					<li className='h5 mt-4 fw-bold '>
						Access and data of individuals under
						16 years of age:
					</li>
					<p>
						We STRONGLY state that no minor below
						the age of 16 years may access the
						Website without the express
						supervision of a guardian. If they are
						to accept this policy and access the
						Services, they shall be deemed to have
						done so under the supervision of a
						guardian. The Company shall in no
						manner be responsible for any and all
						actions taken by a User(s) below the
						age of 16 who is not under the
						supervision of a guardian.
					</p>
				</ol>
			</div>

			<Footer />
		</div>
	)
}

export default PrivacyPolicyScreen
