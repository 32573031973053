import React, { useState, useEffect, useRef } from "react";
import "../CSS/landing.css";
import { getAllBikes } from "../Actions/bikeActions";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../CSS/home.css";
import "../CSS/mobHome.css";
import "../CSS/all-products.css";
import "../CSS/mobproduct.css";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";

export default function StudentLandingPageScreen() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bike, setBike] = useState("");

  const [city, setCity] = useState("");

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const dispatch = useDispatch();

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;

  useEffect(() => {
    dispatch(getAllBikes());
  }, []);
  const formRef = useRef(null);
  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/form/isicLanding",
      { name, email, phoneNumber, bike, city },
      config
    );
    formRef.current.reset();
    if (data) {
      // to rest the modal backdrop issue
      const modal = document.getElementById("exampleModal");
      modal.style.display = "none";
      const modalBackdrops = document.getElementsByClassName("modal-backdrop");
      Array.from(modalBackdrops).forEach((backdrop) => {
        backdrop.parentNode.removeChild(backdrop);
      });

      navigate("/lead-submitted");
    }
  };

  return (
    <>
      <div className="landingpagebody mb-0 overflow-hidden pb-0">
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <form ref={formRef} onSubmit={submit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Interested in premium EMotorad electric cycles?
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="d-lg-none">
                    {/* Content for small screens (less than lg) */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        required
                        className="form-control"
                        id="floatingInput"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name"
                      />
                      <label htmlFor="floatingInput">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        required
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingPassword">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        required
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={phoneNumber}
                        onChange={(e) =>
                          setPhoneNumber(e.target.value.slice(0, 10))
                        }
                      />
                      <label for="floatingPassword">Contact Number</label>
                    </div>
                    <div className="d-flex w-100" style={{ gap: "1em" }}>
                      <div className="form-floating w-50 mb-3">
                        <select
                          className="form-select"
                          onChange={(e) => setBike(e.target.value)}
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option selected disabled></option>
                          <option value="X1">X1</option>
                          <option value="X2">X2</option>
                          <option value="X3">X3</option>
                          <option value="EMX">EMX</option>
                          <option value="EMX+">EMX+</option>
                          <option value="Doodle v2">Doodle v2</option>
                          <option value="T-rex+">T-rex+</option>
                          <option value="Lil E">Lil E</option>
                        </select>
                        <label for="floatingSelect">Electric cycle</label>
                      </div>
                      <div className="form-floating  w-50 mb-3">
                        <input
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                        />
                        <label for="floatingPassword">City</label>
                      </div>
                    </div>
                  </div>

                  <div className="d-none d-lg-block">
                    {/* Content for larger screens (lg and above) */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        required
                        className="form-control"
                        id="floatingInput"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your Name"
                      />
                      <label htmlFor="floatingInput">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        required
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingPassword">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        required
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        value={phoneNumber}
                        onChange={(e) =>
                          setPhoneNumber(e.target.value.slice(0, 10))
                        }
                      />
                      <label for="floatingPassword">Contact Number</label>
                    </div>
                    <div className="d-flex w-100" style={{ gap: "1em" }}>
                      <div className="form-floating w-50 mb-3">
                        <select
                          className="form-select"
                          onChange={(e) => setBike(e.target.value)}
                          id="floatingSelect"
                          aria-label="Floating label select example"
                        >
                          <option selected disabled></option>
                          <option value="X1">X1</option>
                          <option value="X2">X2</option>
                          <option value="X3">X3</option>
                          <option value="EMX">EMX</option>
                          <option value="EMX+">EMX+</option>
                          <option value="Doodle v2">Doodle v2</option>
                          <option value="T-rex+">T-rex+</option>
                          <option value="Lil E">Lil E</option>
                        </select>
                        <label for="floatingSelect">Electric cycle</label>
                      </div>
                      <div className="form-floating  w-50 mb-3">
                        <input
                          onChange={(e) => setCity(e.target.value)}
                          type="text"
                          className="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                        />
                        <label for="floatingPassword">City</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="p-lg-3 p-3 ps-0 pe-0"
          style={{ backgroundColor: "black" }}
        >
          <div className="container-fluid">
            <div className="d-flex landing-nav align-items-center justify-content-between">
              <div>
                <a href="https://www.emotorad.com/">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/white+2.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <picture>
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/393x680.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/1920x850sdsd.jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/1920x850sdsd.jpg"
              className="img-fluid w-100"
              alt=""
              srcSet=""
            />
          </picture>
          <div className="btn-container">
            <button
              className="landing-cta"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
      <div className="container mt-lg-5 mb-lg-5 ">
        <div className="row">
          <div className="col-lg-12 pe-lg-4">
            <div className="row">
              <div className="col-lg-6 p-3">
                <h3 className="mt-lg-4" style={{ fontWeight: "700" }}>
                  Removable Battery
                </h3>
                <p>Batteries that detach, built for versatility, no catch</p>
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "5px",
                  }}
                  playsInline
                  preload="none"
                  className=" img-fluid"
                  src={
                    "https://ar-euro.s3.ap-south-1.amazonaws.com/website_videos/Trex%2B+Removable+Battery.mp4"
                  }
                ></video>
              </div>
              <div className="col-lg-6 p-3">
                <h3 className="mt-lg-4" style={{ fontWeight: "700" }}>
                  Powerful 250W Rear Hub Motor
                </h3>
                <p>Effort that's gentle, performance that's mental</p>
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "5px",
                  }}
                  playsInline
                  preload="none"
                  className=" img-fluid"
                  src={
                    "https://ar-euro.s3.ap-south-1.amazonaws.com/website_videos/Doodle+Motor+Render+C.m4v"
                  }
                ></video>
              </div>
              <div className="col-lg-6 p-3 mt-lg-5">
                <h3 className="mt-lg-4" style={{ fontWeight: "700" }}>
                  Responsive Suspension
                </h3>
                <p>A ride that's well-damped no matter where you're camped</p>
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "5px",
                  }}
                  playsInline
                  preload="none"
                  className=" img-fluid"
                  src={
                    "https://ar-euro.s3.ap-south-1.amazonaws.com/website_videos/Emx+Suspension.mp4"
                  }
                ></video>
              </div>
              <div className="col-lg-6 p-3 mt-lg-5">
                <h3 className="mt-lg-4" style={{ fontWeight: "700" }}>
                  Intuitive Multifunction Display
                </h3>
                <p>Easy to read and bright even under direct sunlight</p>
                <video
                  autoPlay
                  muted
                  loop
                  style={{
                    borderRadius: "5px",
                  }}
                  playsInline
                  preload="none"
                  className="ratio ratio-21x9 "
                  src={
                    "https://ar-euro.s3.ap-south-1.amazonaws.com/website_videos/TREX%2B+display+render.mp4"
                  }
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-lg-5 mt-3 mb-3">
        <h1 className="container-fluid text-passion mb-3 ps-lg-5 text-center">
          Fulfil your Desire, or find your X-Factor!
        </h1>
        <div className="d-flex mt-lg-4 mt-4 justify-content-center">
          <ul
            className="nav nav-pills bg-light  mb-3"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Desire
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                X-Factor
              </button>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade "
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="position-relative ">
              <Splide
                className=" flex align-items-center pt-0"
                options={{
                  arrows: false,
                  type: "loop",
                  pagination: false,
                  autoplay: false,
                  perMove: 1,
                  gap: "3em",
                  padding: "2em",
                  pauseOnHover: false,
                  resetProgress: false,
                  interval: 1000,

                  perPage: 3,

                  speed: 500,
                  breakpoints: {
                    640: {
                      perPage: 1,
                      drag: true,
                      arrows: false,
                      pagination: true,
                      autoplay: false,
                      perMove: 1,
                      gap: "2em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      interval: 1000,
                      focus: 0,
                      start: 0,

                      speed: 500,
                    },
                  },
                }}
              >
                {bikes &&
                  bikes.map((product, i) => {
                    return (
                      product.subCategory === "x-factor" && (
                        <SplideSlide className=" h-100">
                          <div>
                            <Link
                              className="text-decoration-none text-dark"
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <div className="bike-img-bg position-relative">
                                <img
                                  src={product.cartImages[0]}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <h5 className="card-bike-name mt-3 mb-4 text-center text-dark">
                                {product.name}
                              </h5>
                              {product.highLights?.map((highlight, i) => (
                                <p
                                  key={i}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                    fontSize: "0.8em",
                                  }}
                                >
                                  <i className="fa-solid fa-circle-stop text-em "></i>{" "}
                                  {highlight}
                                </p>
                              ))}
                              <hr />
                              <div
                                className="d-flex mt-lg-4 mt-3 mb-lg-4 mb-3 product-card-price"
                                style={{ gap: "1em" }}
                              >
                                <p className="bike-price">
                                  {currencyFormat(product.price)}
                                </p>
                                {product.slashPrice > 0 && (
                                  <p className="slash-bike-price">
                                    {currencyFormat(product.slashPrice)}
                                  </p>
                                )}
                                {product.slashPrice > 0 && (
                                  <span className="save-percent-product-screen">
                                    save &nbsp;
                                    {Math.trunc(
                                      ((product.slashPrice - product.price) /
                                        product.slashPrice) *
                                        100
                                    )}
                                    {"%"}
                                  </span>
                                )}
                              </div>
                            </Link>
                          </div>
                          <button
                            className="bike-cta w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Book Now
                          </button>
                        </SplideSlide>
                      )
                    );
                  })}
              </Splide>
            </div>
          </div>
          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className="position-relative">
              <Splide
                className=" flex align-items-center pt-0"
                options={{
                  arrows: true,
                  drag: true,
                  type: "loop",
                  pagination: true,
                  autoplay: false,
                  perMove: 1,
                  gap: "3em",
                  padding: "2em",
                  pauseOnHover: false,
                  resetProgress: false,
                  interval: 1000,

                  perPage: 3,

                  speed: 500,
                  breakpoints: {
                    640: {
                      perPage: 1,
                      drag: true,
                      arrows: false,
                      pagination: true,
                      autoplay: false,
                      perMove: 1,
                      gap: "2em",
                      padding: "0em",
                      pauseOnHover: false,
                      resetProgress: false,
                      interval: 1000,
                      focus: 0,
                      start: 0,

                      speed: 500,
                    },
                  },
                }}
              >
                {bikes &&
                  bikes.map((product, i) => {
                    return (
                      product.subCategory === "desire" && (
                        <SplideSlide className=" h-100">
                          <div>
                            <Link
                              className="text-decoration-none text-dark"
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <div className="bike-img-bg position-relative">
                                <img
                                  src={product.cartImages[0]}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <h5 className="card-bike-name mt-3 mb-4 text-center text-dark">
                                {product.name}
                              </h5>
                              {product.highLights?.map((highlight, i) => (
                                <p
                                  key={i}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    width: "100%",
                                    fontSize: "0.8em",
                                  }}
                                >
                                  <i className="fa-solid fa-circle-stop text-em "></i>{" "}
                                  {highlight}
                                </p>
                              ))}
                              <hr />
                              <div
                                className="d-flex mt-lg-4 mt-3 mb-lg-4 mb-3 product-card-price"
                                style={{ gap: "1em" }}
                              >
                                <p className="bike-price">
                                  {currencyFormat(product.price)}
                                </p>
                                {product.slashPrice > 0 && (
                                  <p className="slash-bike-price">
                                    {currencyFormat(product.slashPrice)}
                                  </p>
                                )}
                                {product.slashPrice > 0 && (
                                  <span className="save-percent-product-screen">
                                    save &nbsp;
                                    {Math.trunc(
                                      ((product.slashPrice - product.price) /
                                        product.slashPrice) *
                                        100
                                    )}
                                    {"%"}
                                  </span>
                                )}
                              </div>
                            </Link>
                          </div>
                          <button
                            className="bike-cta w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Book Now
                          </button>
                        </SplideSlide>
                      )
                    );
                  })}
              </Splide>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
