import axios from "axios"
import {
	GET_ALL_ACCESSORIES_FAIL,
	GET_ALL_ACCESSORIES_REQUEST,
	GET_ALL_ACCESSORIES_SUCCESS,
	GET_BIKE_BY_NAME_FAIL,
	GET_BIKE_BY_NAME_REQUEST,
	GET_BIKE_BY_NAME_SUCCESS,
	GET_PRODUCT_BY_SUB_CATEGORY_FAIL,
	GET_PRODUCT_BY_SUB_CATEGORY_REQUEST,
	GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS,
	GET_ALLBIKES_REQUEST,
	GET_ALLBIKES_FAIL,
	GET_ALLBIKES_SUCCESS
} from "../Constants/bikeConstants.js"

export const getBikeByName =
	(name) => async (dispatch) => {
		try {
			dispatch({ type: GET_BIKE_BY_NAME_REQUEST })
			const { data } = await axios.get(
				`/api/bikes/bike/${name}`,
			)

			dispatch({
				type: GET_BIKE_BY_NAME_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_BIKE_BY_NAME_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getProductBySubCategory =
	(name) => async (dispatch) => {
		try {
			dispatch({
				type: GET_PRODUCT_BY_SUB_CATEGORY_REQUEST,
			})
			const { data } = await axios.post(
				`/api/bikes/category`,
				{ name },
			)

			dispatch({
				type: GET_PRODUCT_BY_SUB_CATEGORY_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_PRODUCT_BY_SUB_CATEGORY_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllAccessories =
	() => async (dispatch) => {
		try {
			dispatch({
				type: GET_ALL_ACCESSORIES_REQUEST,
			})
			const { data } = await axios.get(
				`/api/bikes/all-accessories`,
			)

			dispatch({
				type: GET_ALL_ACCESSORIES_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ALL_ACCESSORIES_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}

export const getAllBikes =
	() => async (dispatch) => {
		try {
			dispatch({
				type: GET_ALLBIKES_REQUEST,
			})
			const { data } = await axios.get(
				`/api/bikes/all-bikes`,
			)

			dispatch({
				type: GET_ALLBIKES_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_ALLBIKES_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
