import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, cannonicalTag, keywords, product }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />

      <meta property="og:title" content={product?.name} />
      <meta property="og:description" content={product?.description} />
      <meta
        property="og:url"
        content={product && `https://emotorad.com/bikes/${product?.urlName}`}
      />
      <meta property="product:condition" content="new"></meta>
      <meta property="og:image" content={product?.cartImages[0]} />
      <meta property="product:brand" content="EMotorad" />
      <meta
        property="product:availability"
        content={product && product?.inStock > 0 ? "in stock" : "out of stock"}
      />
      <meta property="product:price:amount" content={product?.price} />
      <meta property="product:price:currency" content="INR" />
      <meta property="product:retailer_item_id" content={product?._id} />
      <meta property="google_product_category" content={"ebike"} />
      <link rel="canonical" href={cannonicalTag} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title:
    "EMotorad | Buy Electric Bicycle & Bike Online | Best E Bicycle & E Bike",
  description:
    "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price.",
  keywords: "",
};

export default Meta;
