import React, { useEffect, useState } from "react";
import "../CSS/knowYourEcycle.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { getAllBikes } from "../Actions/bikeActions";
import { useDispatch, useSelector } from "react-redux";
import UserManual from "../Components/UserManual";
import { useNavigate } from "react-router-dom";

const bikeData = [
  {
    name: "X1",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X1+Blue.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1.pdf",
    assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
  },
  {
    name: "X2",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X2.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X2.pdf",
    assemblyVideoURL: "https://youtu.be/Bfkmxy2dhlo?si=ch-JzfslmjC59XdV",
  },
  {
    name: "X3",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X3.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X3.pdf",
    assemblyVideoURL: "https://youtu.be/hLbm8K9NF_0?si=IwnkiP4Q9SVxDFJp",
  },
  {
    name: "Neo",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Neo.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1+Neo.pdf",
    assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
  },
  {
    name: "X1 Limited Edition",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X1+LTD+edition.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1.pdf",
    assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
  },
  {
    name: "Legend 07 MSD Edition",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/legend+07.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1-Dhoni+edition.pdf",
    assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
  },
  {
    name: "EMX",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/EMX.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/emx.pdf",
    assemblyVideoURL: "https://youtu.be/WpntY8JLCJo?si=wCyrFTsYAyeBF_6q",
  },
  {
    name: "EMX+",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/EMX%2B.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/EMX%2B.pdf",
    assemblyVideoURL: "https://youtu.be/WpntY8JLCJo?si=wCyrFTsYAyeBF_6q",
  },
  {
    name: "Doodle V2",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Doodle+V2.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/doodle+V2.pdf",
    assemblyVideoURL: "https://youtu.be/GQLWFT-6ZWs?si=3jFHt0xisY5gR4qv",
  },
  {
    name: "Doodle V3",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Doodle+v3.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/Doodle+V3.pdf",
    assemblyVideoURL: "https://youtu.be/GQLWFT-6ZWs?si=3jFHt0xisY5gR4qv",
  },
  {
    name: 'T-Rex Air 27.5"',
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Trex+air.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/T-Rex+Air+27.5.pdf",
    assemblyVideoURL: "https://youtu.be/qdkltRtWOWI?si=PBHOifp1A3f5_HSk",
  },
  {
    name: 'T-Rex Air 29"',
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Trex+air.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/T-Rex+Air+29.pdf",
    assemblyVideoURL: "https://youtu.be/qdkltRtWOWI?si=PBHOifp1A3f5_HSk",
  },
  {
    name: "T-Rex+ V3",
    image:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Trex+air.mp4",
    userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/Trex%2B_User+Manual.pdf",
    assemblyVideoURL: "https://youtu.be/qdkltRtWOWI?si=PBHOifp1A3f5_HSk",
  },
];
const KnowYourCycle = () => {
  const navigate = useNavigate();
  const [selectedBike, setSelectedBike] = useState('T-Rex Air 29"');
  const [userManualComponent, setUserManualComponent] = useState(false);
  const [warrantyComponent, setWarrantyComponent] = useState(false);
  const handleSelectChange = (event) => {
    setSelectedBike(event.target.value);
  };
  const handleSubmit = () => {
    if (selectedBike) {
      setUserManualComponent(true);
      navigate(`/know-your-ecycle/${selectedBike}`);
    }
  };
  const dispatch = useDispatch();
  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);
  return (
    <div className="know-your-cycle-div">
      <Navbar />
      {!userManualComponent ? (
        bikes && (
          <div className="p-2 position-relative">
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/Select+your+e-cycle+(Mobile).png"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/Select+your+e-cycle+(desktop).png"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/Select+your+e-cycle+(desktop).png"
                alt="Banner1"
                className="d-block w-100"
              />
            </picture>
            <div className="position-absolute select-menu-div">
              <form className="d-flex form-menu" onSubmit={handleSubmit}>
                <select
                  className="form-select form-select-menu py-lg-2 w-50 w-lg-100 required"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                >
                  <option value='T-Rex Air 27.5"' selected>
                    T-Rex Air 27"
                  </option>
                  <option value='T-Rex Air 29"' selected>
                    T-Rex Air 29"
                  </option>
                  {bikes
                    .filter(
                      (bike) =>
                        bike.name !== "EMX Aqua" &&
                        bike.name !== "T-Rex Air" &&
                        bike.name !== "Lil E" &&
                        bike.name !== "T-Rex+ V3" &&
                        bike.name !== "T-Rex+" &&
                        bike.name !== "Doodle V2" &&
                        bike.name !== "X1 Limited Edition"
                    )
                    .map((bike) => (
                      <option value={bike.name}>{bike.name}</option>
                    ))}
                </select>
                <button type="submit" className="btn btn-banner px-4 px-lg-5">
                  Done
                </button>
              </form>
            </div>
          </div>
        )
      ) : (
        <UserManual bike={bikeData.find((b) => b.name === selectedBike)} />
      )}
      <Footer />
    </div>
  );
};

export default KnowYourCycle;
