import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "../CSS/cycle-vs-ecycle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import FormPopUpModal from "../Components/FormPopUp";
import "../CSS/form.css";
import FormTestRide from "../Components/FormTestRide";
import bluelogo from "../images/EM-LOGO_Blue.png";

const CycleVsEcycle = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  SwiperCore.use([Navigation, Pagination]);

  return (
    <>
      <div
        className="position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className=" desktop-nav m-0 p-0 p-3 ps-lg-5 pe-lg-5 ">
          <div className="d-flex justify-content-center">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "170px" }}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="cycle-vs-ecycle">
        {/*hero banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/MOBILE+VIEW+BANNER.png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
              alt="t-Rex+banner"
              className="d-block w-100"
            />
          </picture>
          <div className="position-absolute banner-cta-text d-none d-lg-block">
            <h1 className="text-uppercase text-em">
              <span style={{ fontFamily: "Metropolis-bold" }}>Pedal</span>
              &nbsp;Or&nbsp;
              <span style={{ fontFamily: "Metropolis-bold" }}>Accelerate!</span>
            </h1>
            <div className="d-flex justify-content-center align-items-center ">
              <FormTestRide />
            </div>
          </div>
          <div className="position-absolute w-100 banner-cta-text-mb d-lg-none">
            <div className="d-flex justify-content-center">
              <h1 className="text-uppercase text-em">
                <span style={{ fontFamily: "Metropolis-bold" }}>Pedal</span>
                &nbsp;Or&nbsp;
                <span style={{ fontFamily: "Metropolis-bold" }}>
                  Accelerate!
                </span>
              </h1>
            </div>
          </div>

          <div
            className="position-absolute banner-div-curved d-flex justify-content-center align-items-center"
            onClick={() => setIsModalOpen(true)}
            style={{ cursor: "pointer" }}
          >
            <div className="background-em text-light px-4 py-3 px-lg-5 text-center">
              <h1 className="m-0 banner-text">GET E-CYCLE STARTING AT ₹999</h1>
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-between align-items-center flex-column w-100 banner-cta-btn-mb d-lg-none">
          <FormTestRide />
        </div>

        {/* usp */}
        <div className="px-lg-5 px-3 mt-5 pt-lg-4 usp-comparison ">
          <h2
            className="text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            why choose e-cycle?
          </h2>
          <div className="row">
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/SWEAT+FREE+COMMUTE+ICON.png"
                    className="img-fluid"
                    alt="Sweat Free Commute"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0">
                    sweat Free Commute
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/80%2B+KM+RANGE+ICON.png"
                    className="img-fluid"
                    alt="80+ KM Range"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0">
                    80+ KM Range
                    <br />
                    25 kmph speed
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/NO+COST+EMI+ICON+.png"
                    className="img-fluid"
                    alt="No-Cost EMI"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0">
                    No-Cost <br className="d-lg-none"></br>EMI
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-3">
              <div className="d-flex p-3 usp-comparison-sub-div background-em rounded-4 align-items-center">
                <div className="usp-comparison-img">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/PEDAL+OR+ACCELERATE+ICON.png"
                    className="img-fluid"
                    alt="Pedal or Accelerate"
                  />
                </div>
                <div>
                  <h3 className="text-uppercase text-light mb-0">
                    Pedal or Accelerate
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* cycle vs ecycle */}
        <div className="comparison-landing px-lg-5 mt-4 mt-lg-5">
          <h2
            className="px-3 px-lg-0 mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            E-CYCLE V/S CYCLE
          </h2>
          <div className="d-flex comparison-div">
            <div className="col col-md-6 first-div pe-lg-3 ">
              <picture className="main-banner-img">
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCL_E+COMPARISON+MOBILE.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP.png"
                  alt="t-Rex+banner"
                  className="d-block w-100"
                />
              </picture>
              <div className="comparison-points px-3 px-lg-0 my-lg-3 mt-3">
                <ul>
                  <li>
                    <p className="mb-1">Superior for longer commutes</p>
                  </li>
                  <li>
                    <p className="mb-1">
                      Pedal for fitness or throttle for a sweat-free ride
                    </p>
                  </li>
                  <li>
                    <p className="mb-1">
                      Can replace vehicles, saving significantly on fuel costs
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <hr
              className="d-lg-none"
              style={{ borderTop: "2px solid", opacity: "0.7", margin: "0" }}
            ></hr>
            <div className="col col-md-6 ps-lg-3">
              <picture className="main-banner-img mt-4 mt-lg-0">
                <source
                  media="(max-width:650px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+MOBILE.png"
                />
                <source
                  media="(min-width:651px)"
                  srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP.png"
                />
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP.png"
                  alt="t-Rex+banner"
                  className="d-block w-100"
                />
              </picture>
              <div className="comparison-points px-3 px-lg-0 my-3">
                <ul>
                  <li>
                    <p className="mb-1">Not designed for longer commutes</p>
                  </li>
                  <li>
                    <p className="mb-1">Requires more physical effort</p>
                  </li>
                  <li>
                    <p className="mb-1">
                      Cannot replace vehicles that consume fuel
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => setIsModalOpen(true)}
          className=" text-light mx-auto bg-gradient w-100 d-flex justify-content-center align-items-center w-fit  mt-4"
          style={{ fontFamily: "Metropolis-medium", cursor: "pointer" }}
        >
          <div className="background-em  w-fit d-inline-block px-4 py-2 text-uppercase rounded-3">
            BOOK A TEST RIDE
          </div>
        </div>
        {/* feature */}
        <div className="px-lg-5 px-3 mt-5 features-comparison">
          <h2
            className=" text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            Less Effort, Longer Journeys!
          </h2>

          <div className="d-lg-none">
            <video
              autoPlay
              muted
              loop
              disableRemotePlayback
              playsInline
              className="w-100"
            >
              <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Trex+air+yellow_1.mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={1}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
              },
            }}
            className="inside-div"
          >
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/pedal+assist.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Pedal Assist Technology
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Effortless Rides
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#pedalAssistModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/charging.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Removable Battery
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Charge Anywhere
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#batteryModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/display.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Multifunctional Display
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Stay In Control
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#displayModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/suspension.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Dual Suspension
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Comfort Redefined
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#suspensionModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/range.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    80+ km Range
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Made For Commutes
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#rangeModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/frame.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Foldable Frame
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Compact & Portable
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#frameModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/warranty.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Reliable Warranty
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Peace Of Mind
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#warrantyModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/fat+tyre.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Fat Tyre Option
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Conquer The Roads
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#fatTyreModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* display modal */}
        <div
          class="modal fade"
          id="displayModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Stay ahead with comprehensive real-time information at a
                  glance. It offers essential data such as speed, distance
                  travelled, battery level, and assist mode selection, enhancing
                  the rider's control and convenience while ensuring a safer and
                  more informed cycling experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* pedal assist modal */}
        <div
          class="modal fade"
          id="pedalAssistModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Enhance the riding experience by automatically providing
                  assistance based on pedalling effort. This feature promotes
                  effortless cycling, especially on inclines or longer journeys,
                  offering a seamless blend of human power and electric
                  assistance for a smoother and more enjoyable ride.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* warranty modal */}
        <div
          class="modal fade"
          id="warrantyModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Comprehensive Coverage from frames to batteries and electrical
                  components, our warranty has you covered every step of the
                  way. Ride worry-free at every pedal!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* frame modal */}
        <div
          class="modal fade"
          id="frameModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Also comes with a foldable frame, making it easier to carry
                  anywhere you want. Unfold and zip through traffic with ease.
                  It’s as lightweight as it is stylish. Whether you are
                  commuting or using it for recreational rides, its robust build
                  has you back wherever you go!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* fat tyre modal */}
        <div
          class="modal fade"
          id="fatTyreModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Want to own a fat-tyre electric cycle? We have that too! Take
                  the roads not taken, unlock new adventures and unparalleled
                  fun!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* range modal */}
        <div
          class="modal fade"
          id="rangeModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Provides extended travel distances on a single charge. This
                  capability enables riders to cover longer distances
                  effortlessly, making our electric cycles ideal for commuting
                  and recreational rides without the physical exertion required
                  by traditional cycles.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* suspension modal */}
        <div
          class="modal fade"
          id="suspensionModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Absorbs bumps and vibrations from uneven terrain, enhancing
                  comfort and stability. It improves ride quality by minimising
                  impact on the rider, making it suitable for varied road
                  conditions and ensuring a smoother, more enjoyable cycling
                  experience overall.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* battery modal */}
        <div
          class="modal fade"
          id="batteryModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  The removable battery feature allows for convenient charging
                  and storage. Easily detachable, it helps hassle-free
                  recharging at home or office, ensuring uninterrupted rides
                  without the need for complex infrastructure, making it ideal
                  for urban mobility. Charge like a smartphone, literally!
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* reviews */}
        <div className="px-lg-5 px-3 my-5">
          <h2
            className="text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            hear from the tribe
          </h2>
          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={2}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
                initialSlide: 4,
                allowTouchMove: false,
                loop: false,
                slide: false,
                noSwiping: true,
              },
            }}
            slide
          >
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-1.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-1-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Karan Joshi
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review1Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-2.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-2-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Kalpana Jain
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review2Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-3.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-3-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Sukhveer Singh
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review3Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-4.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-4-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Sanskar Maroo
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review4Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* review 1 modal */}
          <div
            class="modal fade"
            id="review1Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased the Emotorad X1 electric cycle, and it
                    has completely exceeded my expectations. From the sleek
                    design to the powerful performance, this e-bike is a
                    game-changer. The build quality is exceptional, providing a
                    sturdy and reliable feel while riding. The battery life is
                    impressive, allowing for extended rides without worrying
                    about running out of power. The pedal-assist feature is
                    seamless, making uphill climbs a breeze. The electric motor
                    delivers a smooth and responsive ride, and the overall
                    handling is superb. Whether you're a seasoned cyclist or new
                    to electric bikes, the Emotorad X1 offers a fantastic riding
                    experience for all. In conclusion, I highly recommend the
                    Emotorad X1 to anyone looking for a high-quality electric
                    cycle in affordable price. It combines style, performance,
                    and innovation seamlessly, making it a standout choice in
                    the market.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 2 modal */}
          <div
            class="modal fade"
            id="review2Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased the EMotorad Doodle V2 e-cycle, and I
                    am thrilled with my experience! The bike's sleek design,
                    coupled with its powerful electric motor, provides a smooth
                    and enjoyable ride. The battery life exceeded my
                    expectations, allowing for longer rides without worrying
                    about running out of power. The intuitive controls and
                    comfortable seating make it a joy to navigate through
                    various terrains. EMotorad has truly created a fantastic
                    electric cycle that combines style, functionality, and
                    efficiency. I highly recommend the Doodle V2 to anyone
                    looking for a reliable and fun e-cycle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review3 modal */}
          <div
            class="modal fade"
            id="review3Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    The E MOTORAD X2 is a remarkable electric bike that combines
                    impressive performance with exceptional comfort and
                    convenience. With its powerful motor and long-lasting
                    battery, the X2 delivers a smooth and efficient ride, making
                    it perfect for both daily commutes and weekend adventures.
                    The bike's sturdy frame and high-quality components ensure
                    durability and reliability, while its ergonomic design
                    provides a comfortable riding experience even on longer
                    journeys. The X2's compact size and lightweight construction
                    make it easy to manoeuvre in urban environments, while its
                    foldable design allows for convenient storage and
                    transportation. Overall, the E MOTORAD X2 is a fantastic
                    electric bike that offers excellent value for money, making
                    it a top choice for anyone looking for a reliable and
                    enjoyable mode of transportation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 4 modal */}
          <div
            class="modal fade"
            id="review4Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased an e-cycle, and I must say, it has
                    completely transformed my commuting experience. This
                    electric bike combines the convenience of a traditional
                    bicycle with the efficiency of an electric motor, offering a
                    truly versatile mode of transportation.First and foremost,
                    the e-cycle is incredibly eco-friendly. By opting for an
                    electric bike over a traditional vehicle, I'm significantly
                    reducing my carbon footprint and contributing to a cleaner
                    environment. It's empowering to know that my daily commute
                    is now emissions-free.One of the standout features of the
                    e-cycle is its electric motor. With just a simple push of a
                    button, I can effortlessly glide up hills and cover long
                    distances without breaking a sweat. The motor provides a
                    welcome boost, especially during those morning commutes when
                    I'm not quite awake yet.Moreover, the e-cycle is
                    surprisingly versatile. Whether I'm navigating busy city
                    streets or exploring scenic bike paths, it handles
                    beautifully in all environments. The sturdy frame and
                    responsive brakes instill confidence, while the adjustable
                    settings allow me to customize my riding experience to suit
                    my preferences.In terms of convenience, the e-cycle truly
                    shines. It's equipped with a removable battery that can be
                    easily charged at home or the office, ensuring that I always
                    have enough power for my journeys. Plus, the sleek design
                    and compact size make it easy to store in tight spaces,
                    whether it's in my apartment or a crowded bike rack.Overall,
                    I couldn't be happier with my e-cycle purchase. Not only has
                    it revolutionized my daily commute, but it's also helped me
                    lead a more sustainable lifestyle. If you're in the market
                    for an efficient and eco-friendly mode of transportation, I
                    highly recommend considering an e-cycle. It's truly a
                    game-changer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormPopUpModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Footer />
    </>
  );
};

export default CycleVsEcycle;
