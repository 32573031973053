import React from "react";
import "../CSS/about.css";
import Navbar from "../Components/Navbar";
import "@splidejs/react-splide/css";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Footer from "../Components/Footer";
import Meta from "../Components/Meta";
import CallBtn from "../Components/CallBtn";
import WhatsAppBot from "../Components/WhatsAppBot";
import TestRide from "../Components/TestRide";
const AboutScreen = () => {
  return (
    <>
      <Meta
        title={" EMotorad | About Us"}
        description={
          "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
        }
      />
      <CallBtn bottom={"18%"} />
      <WhatsAppBot bottom={"7%"} />
      <TestRide bottom={"0%"} />
      <Navbar />
      <picture className="mt-1">
        <source
          media="(max-width:650px)"
          srcSet={
            "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/about+views.png"
          }
        />
        <source
          media="(min-width:651px)"
          srcSet={
            "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/about.png"
          }
        />
        <img
          src={
            "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/about.png"
          }
          alt="Banner1"
          className="   img-fluid "
        />
      </picture>

      <div className="container p-lg-5 mt-3 mt-lg-0 about-content">
        <div className="row">
          <div className="col-lg-6 pe-lg-5">
            <h5 className="fw-bold text-center">OUR VISION</h5>
            <h5 className="fw-bold text-center">“Best in the World”</h5>
            <p className="mt-3 mb-5">
              Being the best is overrated. Said no one ever at EMotorad. Our
              vision is simple: to add ‘movements of fun’ and ‘innovation’ into
              the lives of every human being and planet. That’s all.
            </p>
            <h5 className="text-center fw-bold">OUR MISSION</h5>
            <h5 className=" text-center">
              Create <b>"Movements of Fun"</b> for People and Planet
            </h5>
            <p className="mt-3">
              We craft our e-cycles with love, only to create moments of fun,
              for you and the planet. We are your quirky friends who have taken
              this simple idea and expanded our roots all over the planet. From
              India to Europe, Japan, Australia, and the UAE - there’s no
              stopping us from capturing even the Milky Way
            </p>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/about/Factory+Image.jpg"
              alt=""
              className="img-fluid mt-lg-5"
            />
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/about/aboutsus-1.jpg"
              className="img-fluid"
              alt=""
            />
            <h5 className="fw-bold text-center mt-5">OUR STORY</h5>
            <h5 className=" text-center">
              “We’re a brand built on crazy dreams!”
            </h5>
            <p className="mt-3 justify-content">
              A short answer to this long question is that we’re a bunch of four
              crazy folks. We are crazy about not just electric cycles but
              optimising how the world views mobility. We are your quirky
              friends who are all about dreams and making them a reality
            </p>
          </div>
        </div>
      </div>
      <div>
        <picture className="mt-1">
          <source
            media="(max-width:650px)"
            srcSet={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/founders_all-mob.jpg"
            }
          />
          <source
            media="(min-width:651px)"
            srcSet={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/founders_all.jpg"
            }
          />
          <img
            src={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/founders_all.jpg"
            }
            alt="Banner1"
            className=" img-fluid "
          />
        </picture>
      </div>

      {/* <div className="container  ">
        <div className="row   founders-card position-relative">
          <div
            className="position-absolute"
            style={{
              width: "100%",
              zIndex: "-2",
              height: "80%",
              backgroundImage:
                'url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/founder+bg.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="col-4 ">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/about/founders/rajib+sir.png"
              alt=""
              className="img-fluid mt-3 w-75 d-block mx-auto"
            />
          </div>
          <div className="col-8">
            <h3 className="founder-name ">Rajib Gangopadhay</h3>
            <p className="founder-title">
              <span
                style={{
                  display: "block",
                  width: "25%",
                  borderBottom: "1px solid #004dac",
                  paddingBottom: "1em",
                }}
              >
                Founder & MD
              </span>
            </p>
            <p className="founder-desc">
              EM was founded by Rajib Gangopadhyay, who has a rich experience in
              international business and sales of e-bikes for 7+ years.
            </p>
          </div>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default AboutScreen;
