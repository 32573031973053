import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../CSS/modal.css";
import logo from "../images/EM-LOGO_Blue.png";

const ModalMobile = (props) => {
  const { className, text } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const modalRef = useRef(null);
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");

  const navigate = useNavigate();

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const displayRazorpay = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = await axios.post(
      "/api/payment/preorder",
      {
        name,
        email,
        phoneNumber,
        address,
        city,
        state,
        pincode,
        bikeInfo: { bikeName: "Legend 07 MSD Edition", color: "bleed blue" },
      },
      config
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { order, preorder } = result.data;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: order.amount.toString(),
      currency: order.currency,
      name: "EMotorad",
      image: logo,
      order_id: order.id,
      handler: async function (response) {
        const data = {
          orderCreationId: order.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          orderId: preorder._id,
        };

        const result = await axios.post(
          "/api/payment/preorder-success",
          data,
          config
        );

        if (result) {
          const bootstrapModal = window.bootstrap.Modal.getInstance(
            modalRef.current
          );
          bootstrapModal.hide();
          navigate("/prebook-success");
        } else {
          console.log("some error occured");
        }
      },
      prefill: {
        name: name,
        email: email,
        contact: phoneNumber,
      },
    };

    const paymentObject = new window.Razorpay(options);
    const paydata = await paymentObject.open(options);
  };

  useEffect(() => {
    if (pincode.length === 6) {
      getAddressDetails();
    }
  }, [pincode]);

  const getAddressDetails = async () => {
    const options = {
      method: "GET",
      url: `https://api.postalpincode.in/pincode/${pincode}`,
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.request(options);
      if (data[0].PostOffice) {
        setCity(data[0].PostOffice[0].District);
        setState(data[0].PostOffice[0].State);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="payment-form-modal">
        <button
          type="button"
          className={` ${className} rounded-5`}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal1"
          style={{ fontSize: "0.9em" }}
        >
          {text}
        </button>

        <div
          className="modal fade"
          ref={modalRef}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-0 pt-2 rounded-4 mx-auto">
              <div className="modal-header p-3 pb-2 pt-2">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form
                  className=" d-flex justify-content-between align-content-center flex-column p-4 pt-0"
                  onSubmit={displayRazorpay}
                >
                  <h5
                    className="mb-3 text-center fw-bold"
                    style={{ color: "#004dac" }}
                  >
                    FILL THE DETAILS
                  </h5>
                  <input
                    type="text"
                    className="form-control input-gifting mb-3 w-100"
                    required
                    value={name}
                    autoComplete="off"
                    style={{ background: "none" }}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    className="form-control input-gifting mb-3 w-100"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="d-flex" style={{ gap: "1em" }}>
                    <input
                      type="tel"
                      placeholder="Phone Number"
                      required
                      className="form-control input-gifting mb-3 w-100"
                      value={phoneNumber}
                      onChange={(e) => {
                        const phoneNumber = e.target.value.slice(0, 12);
                        setPhoneNumber(phoneNumber);

                        if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                          e.target.setCustomValidity("");
                        } else {
                          e.target.setCustomValidity(
                            "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                          );
                        }
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                        )
                      }
                    />
                    <input
                      type="text"
                      className="form-control input-gifting mb-3 w-100"
                      required
                      value={pincode}
                      autoComplete="off"
                      style={{ background: "none" }}
                      onChange={(e) => setPincode(e.target.value)}
                      placeholder="Pincode"
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control input-gifting mb-3 w-100"
                    required
                    value={address}
                    autoComplete="off"
                    style={{ background: "none" }}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Address"
                  />
                  <div className="d-flex" style={{ gap: "1em" }}>
                    <input
                      type="text"
                      className="form-control input-gifting mb-3 w-100"
                      required
                      value={city}
                      name="city"
                      autoComplete="new-password"
                      onChange={(e) => setCity(e.target.value)}
                      style={{ background: "none" }}
                      placeholder="City"
                    />
                    <select
                      name="state"
                      id=""
                      required
                      className="form-select input-gifting mb-3 w-100"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option value="" defaultValue disabled>
                        State*
                      </option>

                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Andaman and Nicobar">
                        Andaman and Nicobar
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Delhi"> Delhi</option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Dadra">Dadra</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Ladakh">Ladakh</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Nagar Haveli ">Nagar Haveli </option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                  </div>
                  <p className="fw-bold mb-2 text-center kalki-buy-font">
                    Legend 07 MSD Edition ₹29,999
                  </p>
                  {/* <div className="d-flex" style={{ gap: "1em" }}>
                  <input
                    type="text"
                    className="form-control input-gifting mb-3 w-100"
                    required
                    value={pincode}
                    autoComplete="off"
                    style={{ background: "none" }}
                    onChange={(e) => setPincode(e.target.value)}
                    placeholder="Pincode"
                  />
                  <input
                    type="text"
                    className="form-control input-gifting mb-3 w-100"
                    required
                    value={city}
                    autoComplete="off"
                    style={{ background: "none" }}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                  />
                  <input
                    type="text"
                    className="form-control input-gifting mb-3 w-100"
                    required
                    value={state}
                    autoComplete="off"
                    style={{ background: "none" }}
                    onChange={(e) => setState(e.target.value)}
                    placeholder="State"
                  />
                </div> */}
                  <button
                    type="submit"
                    className="w-100 btn kalki-btn p-2 rounded-2"
                  >
                    PRE-BOOK @ ₹999
                  </button>
                  <small
                    className="text-center text-secondary mt-4"
                    style={{ fontSize: "0.7em" }}
                  >
                    By choosing to confirm, you consent to share your personal
                    information with EMotorad. Terms and Conditions applied.
                  </small>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalMobile;
