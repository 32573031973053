import React from "react";
import { Link } from "react-router-dom";

const PaymentFailedScreen = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="col-md-5">
        <div
          className="message-box _failed text-center p-4 border border-danger rounded-3"
          style={{ boxShadow: "0 15px 25px #00000019" }}
        >
          <i
            className="fa fa-times-circle text-danger"
            aria-hidden="true"
            style={{ fontSize: "3rem" }}
          ></i>
          <h2 className="mt-3 text-danger fw-bold">Your payment failed</h2>
          <p className="mt-2">Try again later</p>
          <br></br>
          <Link
            className="d-block mx-auto text-center text-em text-decoration-none fw-bold"
            style={{ fontSize: "1.2rem" }}
            to="/cart"
          >
            Go back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailedScreen;
