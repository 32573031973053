import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Meta from "../Components/Meta";
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, Autoplay, EffectCoverflow } from "swiper";

const BlogsScreen = () => {
  SwiperCore.use([Pagination, Autoplay, EffectCoverflow]);

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  const hygraph = new GraphQLClient(
    "https://api-ap-south-1.hygraph.com/v2/clcrn35rt0ct401ur6hv90b6q/master"
  );

  const QUERY = gql`
    {
      posts(orderBy: date_DESC) {
        id
        title
        slug
        tags
        date
        excerpt
        coverImage {
          url
        }
        content {
          html
        }
      }
    }
  `;
  useEffect(() => {
    const getBlogs = async () => {
      const { posts } = await hygraph.request(QUERY);
      setBlogPosts(posts);
    };
    getBlogs();
  }, []);

  return (
    <>
      <>
        <Navbar />
        <Meta
          title={" EMotorad | Blogs"}
          description={
            "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
          }
        />
        <picture>
          <source
            media="(max-width:650px)"
            srcSet={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/blogs/about+us+1st+banner+phone.jpg"
            }
          />
          <source
            media="(min-width:651px)"
            srcSet={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/blogs/about+us+1st+banner.jpg"
            }
          />
          <img
            src={
              "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/blogs/about+us+1st+banner.jpg"
            }
            alt="Banner1"
            className="  w-100 img-fluid"
          />
        </picture>
        <div className="container mt-lg-5 mt-4">
          <h2 className="pb-3 fw-bold  mt-2 pt-lg-2">BLOGS</h2>

          {/* desktop cards */}
          <div className="d-none d-lg-block">
            <div className="row ">
              {blogPosts &&
                blogPosts.map((post, i) => (
                  <div key={i} className="col-4 mb-4">
                    <Link
                      to={`/blogs/${post.slug}`}
                      className="text-decoration-none text-dark"
                    >
                      <div
                        className=" rounded-3 p-2 h-100"
                        style={{ boxShadow: "1px 1px 5px lightgrey" }}
                      >
                        <div>
                          <img
                            src={post?.coverImage?.url}
                            alt=""
                            className="img-fluid rounded-2"
                          />
                        </div>
                        <p
                          style={{ fontSize: "0.9em" }}
                          className="text-muted mt-3 mb-2 "
                        >
                          <span className="pe-2 text-muted">
                            {weekday[new Date(post.date).getDay()]},{" "}
                          </span>

                          <span className="pe-2 text-muted">
                            {new Date(post.date).getDate()} {""}
                            {months[new Date(post.date).getMonth()]}
                          </span>
                          <span className="pe-2 text-muted">
                            {new Date(post.date).getFullYear()}
                          </span>
                        </p>
                        <h6
                          className="fw-bold "
                          style={{
                            display: "-webkit-box",
                            minHeight: "50px",
                            maxWidth: "100%",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {post.title}
                        </h6>
                        <p
                          className="text-muted mt-2"
                          style={{
                            display: "-webkit-box",
                            fontSize: "0.9em",
                            maxWidth: "100%",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            minHeight: "100px",
                          }}
                        >
                          {post.excerpt}
                        </p>

                        <Link
                          to={`/blogs/${post.slug}`}
                          style={{}}
                          className="background-em w-100 text-decoration-none d-block text-center border-0 rounded-3 text-light p-2 w-100"
                        >
                          Read More{" "}
                          <Icon icon="ph:arrow-right-light" width={30} />
                        </Link>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>

          {/* mobile-cards */}

          <div className="blogs-card d-lg-none container mt-0  pb-lg-5 mt-lg-3 p-3  pe-0 mb-2 ps-2">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.2}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              {blogPosts &&
                blogPosts.map(
                  (post, i) =>
                    i < 10 && (
                      <SwiperSlide className="p-1 rounded-3">
                        <div
                          className=" rounded-3 p-2 "
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <Link
                            to={`/blogs/${post.slug}`}
                            className="text-decoration-none text-dark"
                          >
                            <div>
                              <img
                                src={post?.coverImage?.url}
                                alt=""
                                className="img-fluid rounded-2"
                              />
                            </div>
                            <p
                              style={{ fontSize: "0.8em" }}
                              className="text-muted mb-2 mt-2"
                            >
                              <span className="pe-2 text-muted">
                                {weekday[new Date(post.date).getDay()]},
                              </span>

                              <span className="pe-2 text-muted">
                                {new Date(post.date).getDate()} {""}
                                {months[new Date(post.date).getMonth()]}
                              </span>
                              <span className="pe-2 text-muted">
                                {new Date(post.date).getFullYear()}
                              </span>
                            </p>
                            <h6
                              className="fw-bold"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "60px",
                              }}
                            >
                              {post.title}
                            </h6>
                            <p
                              className="text-muted"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "4",
                                fontSize: "0.75em",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "70px",
                              }}
                            >
                              {post.excerpt}
                            </p>
                            <Link
                              to={`/blogs/${post.slug}`}
                              style={{ fontSize: "0.8em" }}
                              className="background-em w-100 d-block text-decoration-none text-center rounded-3 text-light p-2 p-lg-2 border-0 w-100"
                            >
                              Read More{" "}
                              <Icon icon="ph:arrow-right-light" width={20} />
                            </Link>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>

            <Swiper
              className="mt-4"
              spaceBetween={10}
              slidesPerView={1.2}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              {blogPosts &&
                blogPosts.map(
                  (post, i) =>
                    i > 10 && (
                      <SwiperSlide className="p-1 rounded-3">
                        <div
                          className=" rounded-3 p-2 "
                          style={{ boxShadow: "1px 1px 5px lightgrey" }}
                        >
                          <Link
                            to={`/blogs/${post.slug}`}
                            className="text-decoration-none text-dark"
                          >
                            <div>
                              <img
                                src={post?.coverImage?.url}
                                alt=""
                                className="img-fluid rounded-2"
                              />
                            </div>
                            <p
                              style={{ fontSize: "0.8em" }}
                              className="text-muted mb-2 mt-2"
                            >
                              <span className="pe-2 text-muted">
                                {weekday[new Date(post.date).getDay()]},
                              </span>

                              <span className="pe-2 text-muted">
                                {new Date(post.date).getDate()} {""}
                                {months[new Date(post.date).getMonth()]}
                              </span>
                              <span className="pe-2 text-muted">
                                {new Date(post.date).getFullYear()}
                              </span>
                            </p>
                            <h6
                              className="fw-bold"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "60px",
                              }}
                            >
                              {post.title}
                            </h6>
                            <p
                              className="text-muted"
                              style={{
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "4",
                                fontSize: "0.75em",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                minHeight: "70px",
                              }}
                            >
                              {post.excerpt}
                            </p>
                            <Link
                              to={`/blogs/${post.slug}`}
                              style={{ fontSize: "0.8em" }}
                              className="background-em text-decoration-none d-block text-center w-100 rounded-3 text-light p-2 p-lg-2 border-0 w-100"
                            >
                              Read More{" "}
                              <Icon icon="ph:arrow-right-light" width={20} />
                            </Link>
                          </Link>
                        </div>
                      </SwiperSlide>
                    )
                )}
            </Swiper>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default BlogsScreen;
