import React, { useState, useEffect } from "react";
import Meta from "../Components/Meta";
import Navbar from "../Components/Navbar";
import storage from "../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { warrantyCreate } from "../Actions/formActions";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import "../CSS/warranty.css";
import { getAllBikes } from "../Actions/bikeActions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WarrantyScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const warranty = useSelector((state) => state.warranty);
  const { warrantyStatus } = warranty;
  useEffect(() => {
    dispatch(getAllBikes());
  }, []);
  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;

  useEffect(() => {
    if (warrantyStatus) {
      if (sourceOfPurchase === "Amazon") {
        navigate("/submitted/amazon");
      } else if (sourceOfPurchase === "Flipkart") {
        navigate("/submitted/flipkart");
      } else {
        navigate("/submitted");
      }
    }
  }, [warrantyStatus]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bike, setBike] = useState("");
  const [sourceOfPurchase, setsourceOfPurchase] = useState("EM-Website");
  const [frameNumber, setFrameNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [file, setFile] = useState();
  useEffect(() => {
    const data = bikes?.[0]?.name;
    if (data && !bike) {
      setBike(data);
    }
  }, [bikes, bike]);

  let toastId;
  const submitForm = (e) => {
    e.preventDefault();
    fileUpload();
  };

  const fileUpload = () => {
    if (!file) {
      toast.dismiss(toastId);
      toast.error("Please Upload Invoice");
      return;
    }
    toastId = toast.info("Submitting your form...", { autoClose: 3500 });

    const storageRef = ref(storage, `/files/${file.name}+${new Date()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // update progress
      },
      (err) => {
        console.log(err);
      },
      async () => {
        // download url
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        // Include the file URL in the request body
        dispatch(
          warrantyCreate(
            name,
            email,
            phoneNumber,
            sourceOfPurchase,
            bike,
            frameNumber,
            invoiceNumber,
            url
          )
        );
      }
    );
  };

  return (
    <>
      <Meta
        title={"Electric Bicycle Warranty | EMotorad Warranty"}
        description={
          "Ride with confidence - EMotorad's electric bicycle warranty covers you for the long haul. Discover how we safeguard your e-bike experience."
        }
        cannonicalTag={"https://www.emotorad.com/warranty"}
      />
      <Navbar />
      <ToastContainer />
      <div
        className="container-fluid  m-0 pb-0 "
        style={{
          background:
            "linear-gradient(0deg, rgba(0, 48, 128, 1) 0%, #004dac 100%)",
        }}
      >
        <div className="p-0 pb-4 container py-4">
          <div className="mt-lg-0 pt-lg-4 ">
            <div className=" mx-auto">
              <h1
                className="text-light"
                style={{ fontFamily: "Metropolis-bold" }}
              >
                Warranty
              </h1>
              {/* <p className='subline'>
								We’d love to hear from you
							</p> */}
              <form action="" onSubmit={submitForm}>
                <div className="mt-4 d-flex form-content">
                  <div className="form-content-inner-div">
                    <div className="mb-lg-4 mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control "
                        value={name}
                        pattern="[A-Za-z\s]{2,}"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                          e.target.setCustomValidity("");
                        }}
                        style={{
                          textTransform: " capitalize",
                          padding: "0.7em",
                        }}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Name should have at least 2 characters and contain only alphabets."
                          )
                        }
                        id="exampleFormControlInput1"
                        placeholder=""
                      />
                    </div>
                    <div className="mb-lg-4 mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        style={{ padding: "0.7em" }}
                        id="exampleFormControlInput1"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-lg-4 mb-3">
                      <label
                        for="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        style={{ padding: "0.7em" }}
                        id="exampleFormControlInput1"
                        placeholder=""
                        value={phoneNumber}
                        required
                        onChange={(e) => {
                          setPhoneNumber(e.target.value.slice(0, 11));
                          e.target.setCustomValidity("");
                        }}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Enter a valid phone number"
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="form-content-inner-div">
                    <div className="mb-lg-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Select Bike
                      </label>
                      <select
                        onChange={(e) => setBike(e.target.value)}
                        class="form-select "
                        style={{
                          padding: "0.7em",
                        }}
                        aria-label="Default select example"
                      >
                        {bikes?.map(
                          (bike) =>
                            bike.name !== "Nighthawk" &&
                            bike.name !== "Desert Eagle" && (
                              <option key={bike.name} value={bike.name}>
                                {bike.name}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                    <div className="mb-lg-4 mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label text-light "
                      >
                        Select Source of Purchase
                      </label>
                      <select
                        onChange={(e) => {
                          setsourceOfPurchase(e.target.value);
                        }}
                        className="form-select "
                        style={{
                          padding: "0.7em",
                        }}
                        aria-label="Default select example"
                      >
                        <option value="EM-Website">EM-Website</option>
                        <option value="Amazon">Amazon</option>
                        <option value="Flipkart">Flipkart</option>
                        <option value="Dealer">Dealer</option>
                      </select>
                    </div>
                    <div className="d-none d-lg-block">
                      <div className="d-flex" style={{ gap: "1em" }}>
                        <div className="mb-lg-4 mb-3 w-50">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label text-light "
                          >
                            Frame No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            style={{
                              padding: "0.7em",
                            }}
                            id="exampleFormControlInput1"
                            placeholder=""
                            value={frameNumber}
                            required
                            onChange={(e) => setFrameNumber(e.target.value)}
                          />
                        </div>
                        <div className="mb-lg-4 mb-3 w-50">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label text-light "
                          >
                            Invoice No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            style={{
                              padding: "0.7em",
                            }}
                            id="exampleFormControlInput1"
                            placeholder=""
                            value={invoiceNumber}
                            required
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-lg-none">
                      <div className="mb-lg-4 mb-3 w-100">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label text-light "
                        >
                          Frame No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            padding: "0.7em",
                          }}
                          id="exampleFormControlInput1"
                          placeholder=""
                          value={frameNumber}
                          required
                          onChange={(e) => setFrameNumber(e.target.value)}
                        />
                      </div>
                      <div className="mb-lg-4 mb-3 w-100">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label text-light "
                        >
                          Invoice No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            padding: "0.7em",
                          }}
                          id="exampleFormControlInput1"
                          placeholder=""
                          value={invoiceNumber}
                          required
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-5 mb-4 mt-3">
                  <label
                    htmlFor="file"
                    className="form-label text-light text-center  w-100 rounded-3"
                    style={{
                      padding: "1.3em",
                      border: "1.5px dashed white",
                    }}
                  >
                    {file ? file.name : "UPLOAD INVOICE FILE"}
                  </label>
                  <input
                    type="file"
                    hidden
                    onChange={(e) => setFile(e.target.files[0])}
                    id="file"
                    placeholder=""
                  />
                </div>{" "}
                <button
                  type="submit"
                  className="btn mx-auto d-block fw-bold mt-lg-4 text-em mt-3 warranty-btn"
                  style={{ backgroundColor: "white", padding: "0.7em" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container warranty-container">
        <p>
          <br />
        </p>
        <h3
          className="policy-heading mx-auto"
          style={{
            textAlign: "center",
            width: "fit-content",
            borderBottom: "1px solid grey",
          }}
        >
          <b>EMOTORAD WARRANTY POLICY</b>
        </h3>
        <br />
        <p>
          At EMotorad, our first guiding principle is "The Rider is the Boss."
          We stand behind the products that we sell. For this reason, we're
          pleased to offer one of the industry's most generous and
          rider-friendly warranty policies.
        </p>
        <br></br>
        <h5>
          <b>Repair or replacement of components</b>
        </h5>
        <p>
          <b>IMPORTANT!</b> To validate this warranty, the retail customer must
          register the bike at <a href="www.emotorad.com">www.emotorad.com</a>{" "}
          within 15 days of purchase.{" "}
        </p>
        <ul>
          <li>
            <p>
              We offer an extended warranty policy ranging from 5 years to a
              lifetime for EMotorad frames to the original owner against
              structural defects in material or workmanship on various e-bikes
              as listed below.
            </p>
          </li>
          <li>
            <p>
              Other EMotorad branded products (as well as frame paint and
              graphics) are covered by a one-year warranty.
            </p>
          </li>
          <li>
            <p>
              This warranty is limited to the original purchaser only. Proof of
              purchase is required to validate protection under warranty.
              Transport costs are not included.
            </p>
          </li>
          <li>
            <p>
              We offer 12 Months warranty on the motor from the date of original
              Purchase, 12 Months warranty on the controller and 24 months
              warranty on the battery.
            </p>
          </li>
          <li>
            <p>
              The battery warranty does not include damage from power surges,
              use of non-original chargers, improper maintenance, or water
              immersion.
            </p>
          </li>
          <li>
            <p>
              Accessories (for example: helmet, wire lock, pump.) are not
              covered by this warranty policy
            </p>
          </li>
        </ul>
        <br />
        <p>
          Only use this product in accordance with this user manual. EMotorad
          offers a warranty on the following items.
        </p>
        <div align="left">
          <table className="w-100">
            <tbody className="warranty-grid">
              <tr>
                <td>Main frame (Aluminium)</td>
                <td>Lifetime</td>
              </tr>
              <tr>
                <td>Main frame (Steel)</td>
                <td>Five years</td>
              </tr>
              <tr>
                <td>Motor shell, Hub motor, Controller&nbsp;</td>
                <td>One year</td>
              </tr>
              <tr>
                <td>Battery&nbsp;</td>
                <td>Two years</td>
              </tr>
              <tr>
                <td>
                  Electronic handlebar controls and electrical connections,
                  Battery Charger*
                </td>
                <td>Six Months</td>
              </tr>
              <tr>
                <td>Paintwork (excluding accidental or deliberate damage)</td>
                <td>One year</td>
              </tr>
              <tr>
                <td>Lights and lighting system</td>
                <td>Six Months</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          A detailed description of bike frames and their corresponding warranty
          policy is as follows:
        </p>
        <br />
        <div align="left" className="">
          <table
            className="warranty-grid text-center"
            style={{ maxWidth: "100%", overflow: "hidden" }}
          >
            <thead style={{ backgroundColor: "#004dac" }}>
              <td>
                <tr className="fw-bold" style={{ color: "#ffffff" }}>
                  Sr. No.
                </tr>
              </td>
              <td>
                <tr className="fw-bold" style={{ color: "#ffffff" }}>
                  Bike Name
                </tr>
              </td>
              <td>
                <tr className="fw-bold" style={{ color: "#ffffff" }}>
                  Country
                </tr>
              </td>
              <td>
                <tr className="fw-bold" style={{ color: "#ffffff" }}>
                  Frame Material
                </tr>
              </td>
              <td>
                <tr className="fw-bold" style={{ color: "#ffffff" }}>
                  Warranty (On FRAME)
                </tr>
              </td>
            </thead>
            <tbody>
              <tr>
                <td>
                  <tr>1</tr>
                </td>
                <td>
                  <tr>EMX</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>2</tr>
                </td>
                <td>
                  <tr>EMX+</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>3</tr>
                </td>
                <td>
                  <tr>T-REX+ (27.5 inch)</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>4</tr>
                </td>
                <td>
                  <tr>T-REX V2</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>5</tr>
                </td>
                <td>
                  <tr>Doodle V2</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>6</tr>
                </td>
                <td>
                  <tr>Doodle V3</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>7</tr>
                </td>
                <td>
                  <tr>X1</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>High Tensile Steel Frame</tr>
                </td>
                <td>
                  <tr>Up to 5 years</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>8</tr>
                </td>
                <td>
                  <tr>X2</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>High Tensile Steel Frame</tr>
                </td>
                <td>
                  <tr>Up to 5 years</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>9</tr>
                </td>
                <td>
                  <tr>X3</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>High Tensile Steel Frame</tr>
                </td>
                <td>
                  <tr>Up to 5 years</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>10</tr>
                </td>
                <td>
                  <tr>Nighthawk</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>11</tr>
                </td>
                <td>
                  <tr>Desert Eagle</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>Aluminium Alloy</tr>
                </td>
                <td>
                  <tr>Lifetime Warranty</tr>
                </td>
              </tr>
              <tr>
                <td>
                  <tr>12</tr>
                </td>
                <td>
                  <tr>T-Rex Air</tr>
                </td>
                <td>
                  <tr>India</tr>
                </td>
                <td>
                  <tr>High Tensile Steel Frame</tr>
                </td>
                <td>
                  <tr>Up to 5 years</tr>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <ul>
          <li>
            <p>
              All Mechanical parts are only covered for Manufacturing Defects.
              There is no warranty period on handlebars, stem, bearings,
              drivetrain parts, brakes, wires, wire housings, tyres, tubes, rim
              or wheels, hubs, seat or saddle, seat post, clamps, grips, headset
              parts, &nbsp;pedals, brake callipers, bottom bracket, disk, brake
              pads and mudguards.
            </p>
          </li>
          <li>
            <p>
              Display, Throttle, LED Light, Pedal Sensor, Charger - 6 Months
              warranty.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          If any manufacturing defect(s) is found in our product, the Company
          will repair or replace the defective part(s) with new part(s) or
          equivalent at no cost, provided the Product is within warranty period
          of 1 year and the malfunction is caused due to faulty material or
          workmanship during manufacturing. The decision to replace or repair
          the defective part will rest solely with the Company. We also reserve
          the right to replace the defective part with parts, similar or
          different to the defective part, as per the availability of such
          parts.
        </p>
        <br />
        <p>The Warranty policy on our product LIL E is as follows:</p>
        <ol>
          <li>Motor - 1 Year</li>
          <li>Battery - 1 Year</li>
          <li>Frame - 1 Year</li>
          <li>Paint - 1 Year</li>
          <li>Controller - 1 Year</li>
          <li>
            All Other Electricals (Throttle , Brake Light , Front Light ,
            Display) - 6 Months
          </li>
          <li>
            All Mechanical Components will not be covered under warranty. They
            will be covered only for Manufacturing defects
          </li>
        </ol>
        <br />
        <h5>
          <b>Terms &amp; Conditions</b>
        </h5>
        <div align="left">
          <table className="warranty-grid">
            <tbody>
              <tr>
                <td className="text-center">1.</td>
                <td>
                  If the product has a quality fault within 15 days of delivery,
                  the part will be repaired or replaced.
                </td>
              </tr>
              <tr>
                <td className="text-center">2.</td>
                <td>
                  The period of assurance shall commence from the day delivery
                  was made to the retail customer, or from the day the retail
                  customer collected the bike from the retailer.
                </td>
              </tr>
              <tr>
                <td className="text-center">3.</td>
                <td>
                  To validate this warranty, the retail customer must register
                  their bike within 15 days of purchase.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          All parts that have been replaced under warranty must be returned to
          the Company and will be company’s property. Parts replacement will be
          subjected to below terms & conditions:
        </p>
        <ul>
          <li>
            <p>
              The Company or our authorized dealers will repair or replace only
              the parts that are failing due to faulty material.
            </p>
          </li>
          <li>
            <p>
              Only the Company or its authorized dealers can repair, service or
              assemble/ reassemble Product and it shall be the responsibility of
              the customer or purchaser to bring the faulty Product to our
              dealer premises.
            </p>
          </li>
          <li>
            <p>
              This warranty does not cover corrosive parts, plastic, PVC and
              rubber components or the accessories adding to the decorative
              value of the Product.
            </p>
          </li>
          <li>
            <p>
              Photocopy of sales invoice and serial number of the Product has to
              be shared with Company
            </p>
          </li>
          <li>
            <p>
              Battery used in Product is a special application battery meant
              only for electric cycles. Do not use these for any other
              application or product.
            </p>
          </li>
          <li>
            <p>
              The Company reserves the right to change or withdraw any or all
              terms of the warranty policy without any prior intimation.
            </p>
          </li>
          <li>
            <p>
              All Products should be periodically checked as per the recommended
              service schedule by our authorized dealer for indications of
              potential failures including cracks, wire damage, corrosion,
              dents, deformation, paint peeling and any other indications of
              potential problems, inappropriate use or abuse. These are
              important safety checks and very important to help prevent
              accidents, bodily injury to the rider and shortened useful product
              life cycle of a Product.
            </p>
          </li>
          <li>
            <p>
              Since Lithium ion batteries have the tendency to self-degrade,
              mileage per charge will reduce with ageing.
            </p>
          </li>
        </ul>
        <br />
        <p>
          This warranty is not applicable in any of the following
          conditions:&nbsp;
        </p>
        <ul>
          <li>
            <p>
              If any repair work is carried out privately or by any Unauthorized
              dealer or personnel or irregular maintenance
            </p>
          </li>
          <li>
            <p>
              Damage resulting from misuse, not maintaining the vehicle or not
              following the guidelines within our user guide or using the
              vehicle for any kind of competitive sport
            </p>
          </li>
          <li>
            <p>Failure to register bike within 15 days of purchase</p>
          </li>
          <li>
            <p>Spare parts and components worn in normal use</p>
          </li>
          <li>
            <p>
              Any special equipment or accessories added by the customer with an
              intention to enhance the performance, speed or comfort of the
              Product, is not included in this warranty. In the event of any
              such alteration or addition, the warranty will stand null and void
            </p>
          </li>
          <li>
            <p>
              If photocopy of sales invoice is not received with defective
              Product, warranty will be counted from date of manufacturing till
              define time period as above
            </p>
          </li>
          <li>
            <p>
              Fading of parts like paint, stickers and plastic parts due to
              direct exposure of sunlight is not covered under the warranty
            </p>
          </li>
          <li>
            <p>
              This warranty is void in its entirety by any modification of the
              frame, fork or components. This warranty is expressly limited to
              the repair or replacement of a defective item and is the sole
              remedy of warranty. This warranty extends from the date of
              purchase, only to the owner and is not transferable.
              Transportation cost and labor charges incurred in the replacement
              of parts are not covered under this warranty.
            </p>
          </li>
          <li>
            <p>
              The Company is not responsible for loss or damage whatsoever
              (including incidental or consequential damages) arising from use
              of the Product. Any claims under this warranty must be made
              through an authorized dealer
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>The warranty stands null and void if :&nbsp;</p>
            <ul>
              <li>
                <p>
                  The serial number /identification code is deleted, defaced,
                  altered, effaced or removed
                </p>
              </li>
              <li>
                <p>Used for more than permissible load of 120kg</p>
              </li>
              <li>
                <p>
                  Used for stunts, competition or jumping, acrobatics, bicycle
                  moto–cross, dirt biking or similar activities as all units are
                  not designed or intended for such purpose or usage
                </p>
              </li>
              <li>
                <p>Any kind of tampering observed with the parts/Product</p>
              </li>
              <li>
                <p>
                  Modified/altered components for any specific use other than a
                  personal transport
                </p>
              </li>
              <li>
                <p>
                  Transportation, delivery, labour and handling charges incurred
                  in the replacement of parts are not covered under this
                  warranty, and shall be borne by the customer.
                </p>
              </li>
              <li>
                <p>
                  This warranty is void in its entirety by any modification of
                  the frame, fork or components
                </p>
              </li>
              <li>
                <p>Resold to any other person/company</p>
              </li>
              <li>
                <p>
                  Damage caused by an accident or malfunction or misuse caused
                  to the Product by the acts of persons, intentional or
                  otherwise, including but not limited to misuse or mishandling,
                  fire or any act of God
                </p>
              </li>
              <li>
                <p>
                  Nonfunctional parts due to natural wear and tear, ageing,
                  defect resulting from misuse/ improper handling or negligence
                  by the rider or Product is altered by component parts
                  substitution is not covered under the warranty.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          This warranty is valid only within the geographical boundaries of the
          Republic of India. Company reserves the right to change this warranty
          policy at its sole discretion without any notice. In case of any
          dispute with the customer, the courts of Mumbai, Maharashtra, India
          shall have exclusive jurisdiction to settle any dispute.
        </p>
        <br />
        <h5>
          <b>Shipping Damage Claims:</b>
        </h5>
        <p>
          IMMEDIATELY inspect whether the packaging is intact when receiving the
          goods. If you see obvious damage to the product, you can choose to
          reject and contact us in time. We will not accept claims for shipping
          damage later than 24 hours from the receipt of the product.
        </p>
        <br></br>
        <h5>
          <b>PLEASE REGISTER YOUR ELECTRIC BIKE BY SUBMITTING THIS FORM ON:</b>
        </h5>
        <a href="https://www.emotorad.com/warranty">
          https://www.emotorad.com/warranty
        </a>
        <br />
        <br />
        <br />{" "}
        <h4>
          <b>Accessories Warranty</b>
        </h4>
        <p>
          EMotorad also offers warranty on its range of accessories, the details
          of which can be found in this segment.
        </p>
        <p>
          At EMotorad, we believe in taking care of everyone, everything, and
          every being. We stand behind the products that we sell. For this
          reason, we're pleased to offer one of the industry's most generous and
          rider-friendly warranty policies for accessories.
        </p>
        <h5>
          <b>Repair or Replacement of Components</b>
        </h5>
        <p>
          <b>NOTE: </b>This Warranty does not cover battery, normal wear and
          tear, incorrect installation, alterations, tampering damage and
          failure caused by neglect, misuse, abuse or accidents.
        </p>
        <p>
          Only use these products in accordance with the user manual. EMotorad
          offers a warranty on the following accessories.
        </p>
        <table className="warranty-grid text-center">
          <thead style={{ backgroundColor: "#004dac" }}>
            <td>
              <tr className="fw-bold" style={{ color: "#ffffff" }}>
                Accessory
              </tr>
            </td>
            <td>
              <tr className="fw-bold" style={{ color: "#ffffff" }}>
                Cover
              </tr>
            </td>
            <td>
              <tr className="fw-bold" style={{ color: "#ffffff" }}>
                Duration
              </tr>
            </td>
          </thead>
          <tbody>
            <tr>
              <td>
                <tr>Lights</tr>
              </td>
              <td>
                <tr>Charging and Lighting</tr>
              </td>
              <td>
                <tr>Up To Six Months</tr>
              </td>
            </tr>
            <tr>
              <td>
                <tr>Smart Air Pump</tr>
              </td>
              <td>
                <tr>Charging and Lighting</tr>
              </td>
              <td>
                <tr>Up To Six Months</tr>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <p>
          If any manufacturing defect(s) is found in our product, the Company
          will repair or replace the defective part(s) with new part(s) or
          equivalent at no cost, provided the product is within warranty period
          of 6 months and the malfunction is caused due to faulty material or
          workmanship during manufacturing. The decision to replace or repair
          the defective part will rest solely with the Company. We also reserve
          the right to replace the defective part with parts similar or
          different to the defective part, as per the availability of such
          parts.
        </p>
        <h5>
          <b>Terms &amp; Conditions</b>
        </h5>
        <div align="left">
          <table className="warranty-grid">
            <tbody>
              <tr>
                <td className="text-center">1.</td>
                <td>
                  The Company is not responsible for loss or damage whatsoever
                  (including incidental or consequential damages) arising from
                  use of the Product. Any claims under this warranty must be
                  made through an authorised dealer.
                </td>
              </tr>
              <tr>
                <td className="text-center">2.</td>
                <td>
                  This Warranty does not cover normal wear and tear, incorrect
                  installation, alterations or tampering damage or failure
                  caused by neglect, misuse, abuse or accidents.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Company reserves the right to change this warranty policy at its sole
          discretion without any notice. In case of any dispute with the
          customer, the courts of Mumbai, Maharashtra, India shall have
          exclusive jurisdiction to settle any dispute. For offline and online
          purchases, the customer has to reach out to the service team regarding
          the same.
        </p>
        <br />
        <h5>
          <b>Shipping Damage Claims:</b>
        </h5>
        <p>
          IMMEDIATELY inspect whether the packaging is intact when receiving the
          goods. If you see obvious damage to the product, you can choose to
          reject and contact us in time. We will not accept claims for shipping
          damage later than 24 hours from the receipt of the product.
        </p>
      </div>
      <br></br>
      <br></br>
      <Footer />
    </>
  );
};

export default WarrantyScreen;
