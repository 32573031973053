import React, { useState } from "react"
import Footer from "../Components/Footer"
import Navbar from "../Components/Navbar"
import "../CSS/tracking.css"
const TrackingScreen = () => {
	const [tracking, setTracking] = useState("")
	const track = (e) => {
		e.preventDefault()

		window.open(
			`https://track.eshipz.com/track?awb=${tracking}`,
			"_blank",
		)
		setTracking("")
	}
	return (
		<>
			<Navbar />
			<div className='container track-order mt-lg-5 mb-lg-5'>
				<div className='row p-0'>
					<div className='col-lg-6 p-0'>
						<img
							src='https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/original/tracking/Tracker-Page_Desktop-Banner.jpg'
							className='img-fluid'
							alt=''
						/>
					</div>
					<div className='col-lg-5 position-relative offset-lg-1'>
						<h1>Track Your Order</h1>
						<div className='mb-3 mt-lg-5'>
							<label
								for='exampleFormControlInput1'
								className='form-label  '>
								Tracking Order
							</label>
							<input
								type='text'
								className='form-control '
								style={{ padding: "0.7em" }}
								id='exampleFormControlInput1'
								placeholder=''
							/>
						</div>
						<button className='btn btn-primary'>
							Submit
						</button>
					</div>
				</div>
			</div>

			<Footer />
		</>
	)
}

export default TrackingScreen
