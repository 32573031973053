import React, { useEffect, useState } from "react"
import axios from "axios"
import "../CSS/preorder.css"
import { useNavigate } from "react-router-dom"
import logo from "../images/logo1.png"
import logo1 from "../images/white.png"

const PreBookScreen = () => {
	const navigate = useNavigate()

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phoneNumber, setPhoneNumber] =
		useState("")
	const [color, setColor] = useState("")
	const [address, setAddress] = useState("")
	const [bikeName, setBikeName] = useState("")
	const [freeGift, setFreeGift] = useState(false)

	const [bikeIndex, setBikeIndex] = useState([
		0, 0, 0,
	])

	const allBikes = [
		{
			bikeName: "X1",
			tagline: "Experience The Thrill",
			price: "₹24,999",
			link: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Artboard+1.jpg",
			color: [
				{
					colorName: "Deep Blue",
					colorCode:
						"linear-gradient(to bottom left, #447DBA 50%, #383c3e 50%)",
					titleColor: "#c0d9fa",
				},
				{
					colorName: "Traffic Yellow",
					colorCode:
						"linear-gradient(to bottom left, #EED644 50%, #383c3e 50%)",
					titleColor: "#fcf4c2",
				},
			],
			images: [
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B4.png",
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/Y4.png",
			],
			points: [
				"Removable Battery",
				"250W Rear Hub Motor",
			],
		},
		{
			bikeName: "X2",
			tagline: "Lead The Way",
			price: "₹27,999",
			link: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Artboard+2.jpg",

			color: [
				{
					colorName: "Warm Red",
					colorCode:
						"linear-gradient(to bottom left, #EE4C46 50%, #464646 50%)",
					titleColor: "#eb5959",
				},
				{
					colorName: "Sky Blue",
					colorCode:
						"linear-gradient(to bottom left, #C1DAD9 50%, #3f3f3f 50%)",
					titleColor: "#5F918E",
				},
			],
			images: [
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/R2.png",
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/2.png",
			],
			points: [
				"Step Through Frame",
				"P9 LCD Diplay",
			],
		},
		{
			bikeName: "X3",
			tagline: "Feel The Rush",
			price: "₹32,999",
			link: "https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prebook/Artboard+3.jpg",
			color: [
				{
					colorName: "Champagne Gold",
					colorCode:
						"linear-gradient(to bottom left, #D7D5CD 50%, #181818 50%)",
					titleColor: "#e8e1cf",
				},
				{
					colorName: "Polished Silver",
					colorCode:
						"linear-gradient(to bottom left, #DADADA 50%, #181818 50%)",
					titleColor: "#DADADA",
				},
			],
			images: [
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G4.png",
				"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/S4.png",
			],
			points: [
				"LCD Display Integrated with Throttle",
				"3mm Puncture Resistance Tyres",
			],
		},
	]

	const loadScript = (src) => {
		return new Promise((resolve) => {
			const script =
				document.createElement("script")
			script.src = src
			script.onload = () => {
				resolve(true)
			}
			script.onerror = () => {
				resolve(false)
			}
			document.body.appendChild(script)
		})
	}

	useEffect(() => {
		loadScript(
			"https://checkout.razorpay.com/v1/checkout.js",
		)
	})

	const displayRazorpay = async (e) => {
		e.preventDefault()
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		}

		const result = await axios.post(
			"/api/payment/preorder",
			{
				name,
				email,
				phoneNumber,
				address,
				bikeInfo: {
					bikeName,
					color,
				},
				freeGift,
			},
			config,
		)

		if (!result) {
			alert("Server error. Are you online?")
			return
		}

		const { order, preorder } = result.data

		const options = {
			key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
			amount: order.amount.toString(),
			currency: order.currency,
			name: "EMotorad",

			image: logo,
			order_id: order.id,
			handler: async function (response) {
				// console.log(response)
				const data = {
					orderCreationId: order.id,
					razorpayPaymentId:
						response.razorpay_payment_id,
					razorpayOrderId:
						response.razorpay_order_id,
					razorpaySignature:
						response.razorpay_signature,
					orderId: preorder._id,
				}

				const result = await axios.post(
					"/api/payment/preorder-success",
					data,
					config,
				)

				if (result) {
					navigate("/preorder-success")
				} else {
				}
			},
			prefill: {
				name: name,
				email: email,
				contact: phoneNumber,
			},
		}

		const paymentObject = new window.Razorpay(
			options,
		)
		const paydata = await paymentObject.open(
			options,
		)
	}
	return (
		<div className='preorder-page'>
			<div
				className='modal fade'
				id='exampleModal'
				tabIndex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
					<div className='modal-content'>
						<div className='modal-header mb-0'>
							<h5
								className='modal-title'
								id='exampleModalLabel'>
								Book your X-Factor
							</h5>
							<button
								type='button'
								className='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div className='modal-body'>
							<form
								action=''
								onSubmit={displayRazorpay}>
								<div className='form-floating mb-3'>
									<input
										type='text'
										onChange={(e) =>
											setName(e.target.value)
										}
										required
										value={name}
										className='form-control'
										id='floatingInput'
										placeholder='name@example.com'
									/>
									<label for='floatingInput'>
										Name
									</label>
								</div>

								<div className='form-floating mb-3'>
									<input
										type='email'
										onChange={(e) =>
											setEmail(e.target.value)
										}
										value={email}
										required
										className='form-control'
										id='floatingInput'
										placeholder='name@example.com'
									/>
									<label for='floatingInput'>
										Email
									</label>
								</div>

								<div className='form-floating mb-3'>
									<input
										type='number'
										onChange={(e) =>
											setPhoneNumber(
												e.target.value.slice(
													0,
													10,
												),
											)
										}
										required
										value={phoneNumber}
										className='form-control'
										id='floatingInput'
										placeholder='name@example.com'
									/>
									<label for='floatingInput'>
										Phone Number
									</label>
								</div>
								<div className='form-floating mb-3'>
									<textarea
										onChange={(e) =>
											setAddress(e.target.value)
										}
										value={address}
										className='form-control'
										placeholder='Leave a comment here'
										id='floatingTextarea2'
										style={{
											height: "100px",
										}}></textarea>
									<label for='floatingTextarea2'>
										Address
									</label>
								</div>

								<div className='form-floating mb-3'>
									<select
										className='form-select'
										onChange={(e) =>
											setBikeName(e.target.value)
										}
										required
										id='floatingSelect'
										aria-label='Floating label select example'>
										<option disabled selected>
											Select Bike
										</option>
										<option value='X1'>X1</option>
										<option value='X2'>X2</option>
										<option value='X3'>X3</option>
									</select>
									<label for='floatingSelect'>
										Bikes
									</label>
								</div>

								<div className='form-floating mb-3'>
									<select
										required
										className='form-select'
										onChange={(e) =>
											setColor(e.target.value)
										}
										id='floatingSelect'
										aria-label='Floating label select example'>
										<option disabled selected>
											Select Color
										</option>
										{bikeName === "X1" &&
											allBikes[0].color.map(
												(item) => (
													<option
														value={
															item.colorName
														}>
														{item.colorName}
													</option>
												),
											)}
										{bikeName === "X2" &&
											allBikes[1].color.map(
												(item) => (
													<option
														value={
															item.colorName
														}>
														{item.colorName}
													</option>
												),
											)}
										{bikeName === "X3" &&
											allBikes[2].color.map(
												(item) => (
													<option
														value={
															item.colorName
														}>
														{item.colorName}
													</option>
												),
											)}
									</select>
									<label for='floatingSelect'>
										Colors
									</label>
								</div>

								<div className=' mb-3'>
									<p className='mb-1'>
										Would you like a free gift?
									</p>
									<div className='d-flex'>
										<div className='col-6 pe-2'>
											<button
												type='button'
												className={`btn ${
													freeGift === false &&
													"btn-primary"
												} w-100`}
												onClick={() => {
													setFreeGift(false)
												}}>
												No
											</button>
										</div>
										<div className='col-6 ps-2'>
											<button
												type='button'
												className={`btn ${
													freeGift === true &&
													"btn-primary"
												} w-100`}
												onClick={() => {
													setFreeGift(true)
												}}>
												Yes
											</button>
										</div>
									</div>
								</div>
								<hr />
								<button
									type='submit'
									data-bs-dismiss='modal'
									aria-label='Close'
									className='btn btn-success w-100 pt-2 pb-2'>
									Book Now @ ₹999
								</button>
							</form>
						</div>
						<div className='modal-footer'></div>
					</div>
				</div>
			</div>

			{/* .............. */}
			<div className='section-1 d-none d-lg-block position-relative'>
				<img
					src={
						"https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/black+1.png"
					}
					style={{
						top: "2%",
						left: "3%",
						width: "15%",
					}}
					className=' position-absolute img-fluid'
					alt=''
				/>
				{/* <img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/landingpage/X-Factor+Banner+2.png'
					className='img-fluid'
					alt=''
				/> */}
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/landingpage/tghy.jpg'
					className='img-fluid'
					alt=''
				/>
				<div
					className='position-absolute pt-4 pb-2 section-1-overlay w-100'
					style={{ bottom: "5%" }}>
					<h2 className=' mb-3  fw-bold text-center'>
						Prebook the X-Factor at just ₹999*
					</h2>

					<button
						data-bs-toggle='modal'
						data-bs-target='#exampleModal'
						className='btn mb-3 btn-dark d-block mx-auto ps-4 fe-bold pe-4'
						style={{
							borderRadius: "30px",
							fontSize: "1.3em",
						}}>
						Book Now
					</button>
				</div>
				<small
					className='fst-italic position-absolute d-block mt-4 pe-3 text-end'
					style={{
						fontSize: "1em",
						color: "#1a1a1a",
						bottom: "3%",
						right: "0%",
					}}>
					*Non Refundable Deposit
				</small>
			</div>

			<div className='section-1 d-lg-none position-relative'>
				<img
					src={logo1}
					style={{
						top: "2%",
						left: "3%",
						width: "35%",
					}}
					className=' position-absolute img-fluid'
					alt=''
				/>
				<img
					src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/landingpage/X-Factor+Banner+2.png'
					className='img-fluid'
					alt=''
				/>
				<div
					className='position-absolute pt-4 pb-2 section-1-overlay-mb w-100'
					style={{ bottom: "0%" }}>
					<h1 className='text-light fw-bold text-center'>
						FINALLY!!
					</h1>
					<p className='text-light text-center mb-0'>
						Prebook the X-Factor at just
					</p>
					<h2 className='fw-bold text-center text-light'>
						₹999
					</h2>
					<button
						data-bs-toggle='modal'
						data-bs-target='#exampleModal'
						className='btn btn-light d-block mx-auto ps-4 fe-bold pe-4'
						style={{
							borderRadius: "20px",
							fontSize: "0.8em",
						}}>
						Book Now
					</button>

					<small
						className='fst-italic d-block mt-4 pe-3 text-end'
						style={{
							fontSize: "0.7em",
							color: "#cdd8d8",
						}}>
						*Non Refundable Deposit
					</small>
				</div>
			</div>

			{/* ................ */}

			<div className='container overflow-x-hidden  bike-box '>
				<div className='row align-items-lg-center'>
					<div className='col-12 position-relative overflow-hidden d-block d-lg-none mt-5 mb-3 col-lg-7'>
						<img
							src={
								allBikes[0].images[bikeIndex[0]]
							}
							style={{ scale: "1.17" }}
							className='img-fluid d-block mx-auto'
							alt=''
						/>

						<h1
							style={{
								top: "40%",
								left: "45%",
								translate: "-50% -50%",
								fontSize: "15em",
								zIndex: "-1",
								color:
									allBikes[0].color[bikeIndex[0]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X1
						</h1>
					</div>
					<div className='col-12 col-lg-5'>
						<h3 className='mb-2'>
							X1 - EXPERIENCE THE THRILL
						</h3>
						<p>
							X1 is a pathbreaking e-bike designed
							to take on rough terrain with the
							same energy as on the city streets.
							It is the perfect e-bike for
							off-road adventures, city commutes
							and exploring the outdoors.
						</p>
						<h3 className='mb-4'>
							{allBikes[0].price}
						</h3>

						<h6 className='text-muted mb-0'>
							Colors
						</h6>

						<div
							className='d-flex  mt-2 '
							style={{ gap: "1em" }}>
							{allBikes[0].color.map(
								(color, index) => (
									<div
										style={{
											borderRadius: "50%",
										}}
										className={` p-1 ${
											index === bikeIndex[0] &&
											" event-active-color"
										}`}>
										{" "}
										<div
											className={`bike-color  `}
											onClick={() => {
												let data = [...bikeIndex]
												data[0] = index
												setBikeIndex(data)
											}}
											style={{
												background:
													color.colorCode,
											}}></div>
									</div>
								),
							)}
						</div>

						<div
							className='highlights d-flex mt-4 p-2 p-lg-3'
							style={{
								boxShadow:
									" -5px 5px 0px #b5a8a8 ",
								borderRadius: "5px",
								border: "1px solid #b5a8a8",
							}}>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									250W
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Motor
								</p>
							</div>
							<div
								className='col-4'
								style={{
									borderRight: "1px solid grey",
									borderLeft: "1px solid grey",
								}}>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									7.8Ah
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Battery
								</p>
							</div>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									40+KM*
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Range
								</p>
							</div>
						</div>
						<div className='d-flex mt-4 mt-lg-5 '>
							<div className='col-12  ps-1'>
								<button
									className='btn pt-lg-3  pb-lg-3 btn-dark w-100 '
									data-bs-toggle='modal'
									data-bs-target='#exampleModal'
									style={{
										fontSize: "1.2em",
									}}>
									Book @ ₹999
								</button>
							</div>
						</div>
					</div>
					<div className='col-12  position-relative d-none d-lg-block col-lg-7'>
						<img
							src={
								allBikes[0].images[bikeIndex[0]]
							}
							style={{ scale: "1.3" }}
							className='img-fluid d-block mx-auto'
							alt=''
						/>
						<h1
							style={{
								top: "20%",
								right: "17%",
								translate: "50% -50%",
								fontSize: "15em",
								zIndex: "-1",
								color:
									allBikes[0].color[bikeIndex[0]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X1
						</h1>
					</div>
				</div>
			</div>

			<div className='container overflow-x-hidden  bike-box '>
				<div className='row align-items-lg-center'>
					<div className='col-12 position-relative overflow-hidden d-block d-lg-none mt-5 mb-3 col-lg-7'>
						<img
							src={
								allBikes[1].images[bikeIndex[1]]
							}
							style={{
								scale: "1.17",
								zIndex: "-2",
							}}
							className='img-fluid d-block mx-auto'
							alt=''
						/>

						<h1
							style={{
								top: "40%",
								left: "45%",
								translate: "-50% -50%",
								fontSize: "13em",
								zIndex: "-3",
								color:
									allBikes[1].color[bikeIndex[1]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X2
						</h1>
					</div>
					<div className='col-12  position-relative d-none d-lg-block col-lg-7'>
						<img
							src={
								allBikes[1].images[bikeIndex[1]]
							}
							style={{
								scale: "1.3",
								zIndex: "-2",
							}}
							className='img-fluid d-block mx-auto'
							alt=''
						/>
						<h1
							style={{
								top: "20%",
								left: "0%",
								translate: "0% -50%",
								fontSize: "15em",
								zIndex: "-3",
								color:
									allBikes[1].color[bikeIndex[1]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X2
						</h1>
					</div>
					<div className='col-12 col-lg-5'>
						<h3 className='mb-2'>
							X2 - LEAD THE WAY
						</h3>
						<p>
							X2 is the mountain e-bike made for
							everyone - a unisex design frame
							that features a lowered top tube for
							convenience. Step through and ride
							effortlessly as the powerful 250w
							rear hub motor injects fresh energy
							into your rides.
						</p>
						<h3 className='mb-4'>
							{allBikes[1].price}
						</h3>

						<h6 className='text-muted mb-0'>
							Colors
						</h6>

						<div
							className='d-flex  mt-2 '
							style={{ gap: "1em", zIndex: "4" }}>
							{allBikes[1].color.map(
								(color, index) => (
									<div
										style={{
											borderRadius: "50%",
											zIndex: "4",
										}}
										className={` p-1 ${
											index === bikeIndex[1] &&
											" event-active-color"
										}`}>
										{" "}
										<div
											className={`bike-color  `}
											onClick={() => {
												let data = [...bikeIndex]
												data[1] = index
												setBikeIndex(data)
											}}
											style={{
												background:
													color.colorCode,
											}}></div>
									</div>
								),
							)}
						</div>
						<div
							className='highlights d-flex mt-4 p-2 p-lg-3'
							style={{
								boxShadow:
									" -5px 5px 0px #b5a8a8 ",
								borderRadius: "5px",
								border: "1px solid #b5a8a8",
							}}>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									250W
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Motor
								</p>
							</div>
							<div
								className='col-4'
								style={{
									borderRight: "1px solid grey",
									borderLeft: "1px solid grey",
								}}>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									7.8Ah
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Battery
								</p>
							</div>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									40+KM*
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Range
								</p>
							</div>
						</div>
						<div className='d-flex mt-4 mt-lg-5 '>
							<div className='col-12  ps-1'>
								<button
									className='btn pt-lg-3  pb-lg-3 btn-dark w-100 '
									data-bs-toggle='modal'
									data-bs-target='#exampleModal'
									style={{
										fontSize: "1.2em",
									}}>
									Book @ ₹999
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='container overflow-x-hidden  bike-box '>
				<div className='row align-items-lg-center'>
					<div className='col-12 position-relative overflow-hidden d-block d-lg-none mt-5 mb-3 col-lg-7'>
						<img
							src={
								allBikes[2].images[bikeIndex[2]]
							}
							style={{ scale: "1.17" }}
							className='img-fluid d-block mx-auto'
							alt=''
						/>

						<h1
							style={{
								top: "40%",
								left: "45%",
								translate: "-50% -50%",
								fontSize: "15em",
								zIndex: "-1",
								color:
									allBikes[2].color[bikeIndex[2]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X3
						</h1>
					</div>
					<div className='col-12 col-lg-5'>
						<h3 className='mb-2'>
							X3 - FEEL THE RUSH
						</h3>
						<p>
							A distinctive gravel e-bike, X3
							possesses hybrid prowess to master
							relaxed streets or rough roads. lt
							pairs high-performance geometry and
							puncture-resistant tyres with a 250W
							rear hub motor to make it a fast and
							easy ride.
						</p>
						<h3 className='mb-4'>
							{allBikes[2].price}
						</h3>

						<h6 className='text-muted mb-0'>
							Colors
						</h6>

						<div
							className='d-flex  mt-2 '
							style={{ gap: "1em" }}>
							{allBikes[2].color.map(
								(color, index) => (
									<div
										style={{
											borderRadius: "50%",
										}}
										className={` p-1 ${
											index === bikeIndex[2] &&
											" event-active-color"
										}`}>
										{" "}
										<div
											className={`bike-color  `}
											onClick={() => {
												let data = [...bikeIndex]
												data[2] = index
												setBikeIndex(data)
											}}
											style={{
												background:
													color.colorCode,
											}}></div>
									</div>
								),
							)}
						</div>
						<div
							className='highlights d-flex mt-4 p-2 p-lg-3'
							style={{
								boxShadow:
									" -5px 5px 0px #b5a8a8 ",
								borderRadius: "5px",
								border: "1px solid #b5a8a8",
							}}>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									250W
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Motor
								</p>
							</div>
							<div
								className='col-4'
								style={{
									borderRight: "1px solid grey",
									borderLeft: "1px solid grey",
								}}>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									7.0Ah
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Battery
								</p>
							</div>
							<div className='col-4'>
								<h5 className='fw-bold m-0 mt-1 text-center'>
									40+KM*
								</h5>
								<p
									style={{ fontSize: "0.8em" }}
									className='m-0 text-center font-mon text-muted'>
									Range
								</p>
							</div>
						</div>
						<div className='d-flex mt-4 mt-lg-5 '>
							<div className='col-12  ps-1'>
								<button
									className='btn pt-lg-3  pb-lg-3 btn-dark w-100 '
									data-bs-toggle='modal'
									data-bs-target='#exampleModal'
									style={{
										fontSize: "1.2em",
									}}>
									Book @ ₹999
								</button>
							</div>
						</div>
					</div>
					<div className='col-12  position-relative d-none d-lg-block col-lg-7'>
						<img
							src={
								allBikes[2].images[bikeIndex[2]]
							}
							style={{ scale: "1.3" }}
							className='img-fluid d-block mx-auto'
							alt=''
						/>
						<h1
							style={{
								top: "20%",
								right: "17%",
								translate: "50% -50%",
								fontSize: "15em",
								zIndex: "-1",
								color:
									allBikes[2].color[bikeIndex[2]]
										.titleColor,
							}}
							className=' position-absolute bike-heading'>
							X3
						</h1>
					</div>
				</div>
			</div>

			{/* table */}
			<div
				className='container '
				style={{ marginTop: "8em" }}>
				<h1 className='fw-bold'>Compare Bikes</h1>
				<div className=' overflow-auto '>
					<div className='d-flex align-items-baseline'>
						<div className='col-lg-2 border-bottom col-4'></div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4'>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X2/B1.png'
									className='img-fluid d-block mx-auto '
									alt=''
								/>
							</div>
							<div className='col-12 border-bottom col-lg-4'>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X1/R1.png'
									className='img-fluid d-block mx-auto'
									alt=''
								/>
							</div>
							<div className='col-12 border-bottom col-lg-4'>
								<img
									src='https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/new-images/product-images/X3/G1.png'
									className='img-fluid d-block mx-auto'
									alt=''
								/>
							</div>
						</div>
					</div>
					{/* specs */}
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Motor
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=' '>
									EMotorad 36V 250W Rear Hub Motor
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=' '>
									EMotorad 36V 250W Rear Hub Motor
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=' '>
									EMotorad 36V 250W Rear Hub Motor
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Battery
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=''>
									36V 7.8Ah Li-Ion Removable
									Battery
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=''>
									36V 7.8Ah Li-Ion Removable
									Battery
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=''>
									36V 7.0Ah Li-Ion Frame
									Integrated Battery. Panasonic
									Cells
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Display
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=''>
									Battery Indicator on Throttle
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=''>
									P9 LCD Display
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className=' '>
									Color Display
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Pedal Assist
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									1 Level of Pedal Assist 12
									Magnets
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									3 Levels of Pedal Assist 12
									Magnets
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									3 Levels of Pedal Assist 12
									Magnets
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted'>
								Range
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									35 to 40 KM on PAS and 30 Kms on
									Throttle
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									35 to 40 KM on PAS and 30 Kms on
									Throttle
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold'>
									30 to 40 KM on PAS and 30 Kms on
									Throttle
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Charging Time
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									4-5 hrs
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									4-5 hrs
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									4-5 hrs
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Frame
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									High Tensile Steel Frame
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									High Tensile Step-Through Steel
									Frame
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									High Tensile Steel Frame
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Tyre
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									27.5*2.1 Hindustan Tyres
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									27.5*2.1 Hindustan Tyres
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									700X45 C Hartex Grit 60 TPI with
									Tru Side Tan wall (Puncture
									Resistance 3mm)
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Brakes
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									Mechanical Disc Brakes with Auto
									Cut-off
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									Mechanical Disc Brakes with Auto
									Cut-off
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									Mechanical Disc Brakes with Auto
									Cut-off
								</h6>
							</div>
						</div>
					</div>
					<div className='d-flex'>
						<div className='col-lg-2 border-bottom  col-4 p-3'>
							<h6 className='fw-bold  text-muted '>
								Front Fork
							</h6>
						</div>
						<div className='d-flex   col-10'>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									100 mm Travel
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									100 mm Travel
								</h6>
							</div>
							<div className='col-12 border-bottom col-lg-4 p-3'>
								<h6 className='fw-bold '>
									60 mm Travel
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='benifits mt-5 '>
				<div
					className='pt-4 pb-5 text-light ps-2'
					style={{
						backgroundImage: `url('https://ar-euro.s3.ap-south-1.amazonaws.com/website_images/prelaunch/landingpage/X-Factor+Banner+3.png')`,
						backgroundSize: "cover",
					}}>
					{/* <img src="" className="img-fluid" alt="" /> */}

					<div className='container'>
						<h3 className='fw-bold'>
							Benefits of Members Access
						</h3>

						<ul
							className=''
							style={{ fontSize: "0.9em" }}>
							<li>Early access offers </li>
							<li>Special member discounts</li>
							<li>
								Regular ‘Members Only’ EM merch
								giveaway
							</li>
							<li>Referral bonus</li>
							<li>Loyalty bonus</li>
						</ul>
						<button
							data-bs-toggle='modal'
							data-bs-target='#exampleModal'
							className='btn btn-light d-block ms-auto me-3 ps-4 fe-bold pe-4'
							style={{
								borderRadius: "20px",
								fontSize: "1em",
							}}>
							Book Now
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PreBookScreen
