import React, { useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "../CSS/waitlistLandingPage.css";
import ImageGallery from "react-image-gallery";

function DoodleLandingPage() {
  const ind = useRef(0);
  const [fullScreenClose, setfullScreenClose] = useState(false);
  const doodleImages = [
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_1.jpg",
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_2.jpg",
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_3.jpg",
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_4.jpg",
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_5.jpg",
    "https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle+product+images/doodle_6.jpg",
  ];
  return (
    <>
      <Navbar />
      <div className="position-relative">
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle_landingpage_mobile.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle_landingpage_website.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/doodle_landingpage_website.jpg"
            className="img-fluid w-100"
            alt=""
            srcSet=""
          />
        </picture>
        <div className="btn-container-newlaunch">
          <button
            className="newlaunch-doodle-cta"
            onClick={() =>
              (window.location.href =
                "https://pages.razorpay.com/pl_NLJDbjC6AKnNuV/view")
            }
          >
            Pre-book Now
          </button>
        </div>
      </div>
      <div
        className="container-fluid pb-5 pt-5"
        style={{ background: "black" }}
      >
        <div className="row align-items-center">
          <div className="col-lg-7 offset-lg-1 p-0  ">
            <div className="position-relative p-lg-0 p-2">
              <ImageGallery
                items={doodleImages.map((image) => ({
                  original: image,
                  thumbnail: image,
                }))}
                ref={ind}
                onClick={() => {
                  if (fullScreenClose) {
                    ind.current.exitFullScreen();
                    setfullScreenClose(false);
                  } else {
                    ind.current.fullScreen();
                    setfullScreenClose(true);
                  }
                }}
                useBrowserFullscreen={false}
                className="img-fluid d-flex w-75 align-items-center"
                showPlayButton={false}
                showThumbnails={false}
                additionalClassName="img-fluid "
                renderLeftNav={(onClick, disabled) => (
                  <iconify-icon
                    icon="ic:baseline-keyboard-arrow-left"
                    style={{
                      color: "white",
                      zIndex: "1",
                      position: "absolute",
                      top: "47%",
                      left: "4%",
                    }}
                    onClick={onClick}
                    disabled={disabled}
                    width="50"
                  ></iconify-icon>
                )}
                renderRightNav={(onClick, disabled) => (
                  <iconify-icon
                    icon="ic:baseline-keyboard-arrow-right"
                    style={{
                      color: "white",
                      zIndex: "1",
                      position: "absolute",
                      top: "47%",
                      right: "4%",
                    }}
                    onClick={onClick}
                    disabled={disabled}
                    width="50"
                  ></iconify-icon>
                )}
              />
            </div>
            <div
              className="d-flex p-2 pt-1 p-lg-0 p-md-2 mt-lg-2 justify-content-center"
              style={{ gap: "0.5em" }}
            >
              {doodleImages.map((image, i) => (
                <div
                  key={i}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    ind.current.slideToIndex(i);
                  }}
                  className="p-2   pt-0 pb-0   ps-0 pe-0"
                >
                  <div className=" border ">
                    <img className="img-fluid   " src={image} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-lg-4 "
            style={{ color: "white", paddingBottom: "1.3em" }}
          >
            <div className="bike-info ps-lg-5 pe-lg-5 position-relative ">
              <h1 className="fw-bold mt-4 mt-lg-0 mb-5">
                Doodle V3 - unfolds to perfection
              </h1>
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/icons/batteryIcon.png"
                  alt="batteryIcon"
                  className="icon-img"
                ></img>
                <h4 className="key-sub-features doodle-newlaunch-h4">
                  12.75Ah Battery
                </h4>
              </div>
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/icons/throttleIcon.png"
                  alt="batteryIcon"
                  className="icon-img"
                ></img>
                <h4 className="key-sub-features doodle-newlaunch-h4">
                  Throttle with lock
                </h4>
              </div>
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/icons/displayIcon.png"
                  alt="batteryIcon"
                  className="icon-img"
                ></img>
                <h4 className="key-sub-features doodle-newlaunch-h4">
                  M6H LCD Display with USB
                </h4>
              </div>
              <div
                className=""
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/landingPage/icons/frameIcon.png"
                  alt="batteryIcon"
                  className="icon-img"
                ></img>
                <h4 className="key-sub-features doodle-newlaunch-h4">
                  Aluminiun Alloy Foldable Frame
                </h4>
              </div>
              <div className="btn-container-productdiv mb-5">
                <button
                  className="newlaunch-doodle-cta"
                  onClick={() =>
                    (window.location.href =
                      "https://pages.razorpay.com/pl_NLJDbjC6AKnNuV/view")
                  }
                >
                  Pre-book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#960201",
          padding: "0.8em",
        }}
      >
        <h2
          className="text-center bottom-passion mb-0"
          style={{ color: "#f8f4f1" }}
        >
          DOODLE V3 LAUNCH PRICE VALID TILL 10TH JAN!
        </h2>
      </div>
      <Footer></Footer>
    </>
  );
}

export default DoodleLandingPage;
