import React, { useEffect, useRef, useState } from "react";
import Footer from "../Components/Footer";
import Meta from "../Components/Meta";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/home.css";
import "../CSS/mobHome.css";
import { getAllBikes } from "../Actions/bikeActions";
import SwiperCore, { Pagination, Autoplay, EffectCoverflow } from "swiper";
import Navbar from "../Components/Navbar";
import CallBtn from "../Components/CallBtn";
import WhatsAppBot from "../Components/WhatsAppBot";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import TestRide from "../Components/TestRide";
import "swiper/swiper.min.css";
import { Icon } from "@iconify/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import the CSS for blur effect
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import LazyLoad from "react-lazy-load";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const ytRef = useRef();
  const ytThumbnailRef = useRef();
  SwiperCore.use([Pagination, Autoplay, EffectCoverflow]);

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  const [modelStates, setModelStates] = useState(
    bikes ? Array(bikes.length).fill(0) : []
  );
  const handleColorClick = (bikeIndex, colorIndex) => {
    setModelStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[bikeIndex] = colorIndex;
      return newStates;
    });
  };

  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);

  useEffect(() => {
    if (bikes) {
      setModelStates(Array(bikes.length).fill(0));
    }
  }, [bikes]);

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  if (
    ytRef.current &&
    ytThumbnailRef.current &&
    ytThumbnailRef.current.splide
  ) {
    ytRef.current.sync(ytThumbnailRef.current.splide);
    ytThumbnailRef.current.sync(ytRef.current.splide);
  }

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [activeItem, setActiveItem] = useState(1);
  const [activeSwiper, setActiveSwiper] = useState(4);
  const rangeData = [
    {
      id: 1,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/product+images/yellow/X1+Yellow+img+1.png",
      url2: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/product+images/yellow/X1+Yellow+img+3.png",
      name: "x-factor",
      description:
        "Adding the extra edge to your daily travels, The X-Factor range of e-bikes prove to be adaptable and affordable rides suitable for everyone, beginner or pro.",
    },
    {
      id: 2,
      url1: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/product+images/neon/Emx+Plus+img+1.png",
      url2: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/product+images/neon/Emx+Plus+img+3.png",
      name: "desire",
      description:
        "Adding the extra edge to your daily travels, The Desire range of e-bikes prove to be adaptable and affordable rides suitable for everyone, beginner or pro.",
    },
  ];
  const handleHover = (id) => {
    if (activeItem === id) {
      return;
    }
    setActiveItem(id);
  };
  const handleHoverLeave = () => {
    if (activeItem === 1) {
      return;
    }
    setActiveItem(1);
  };
  const handleSlideChange = (swiper) => {
    setActiveSwiper(swiper.activeIndex);
  };
  const [isQuestionOne, setIsQuestionOne] = useState(false);
  const [isQuestionTwo, setIsQuestionTwo] = useState(false);
  const [isQuestionThree, setIsQuestionThree] = useState(false);
  const [isQuestionFour, setIsQuestionFour] = useState(false);
  const [isQuestionFive, setIsQuestionFive] = useState(false);
  const [isQuestionSix, setIsQuestionSix] = useState(false);

  const toggleQuestionOneCollapse = () => {
    setIsQuestionOne(!isQuestionOne);
  };
  const toggleQuestionTwoCollapse = () => {
    setIsQuestionTwo(!isQuestionTwo);
  };
  const toggleQuestionThreeCollapse = () => {
    setIsQuestionThree(!isQuestionThree);
  };
  const toggleQuestionFourCollapse = () => {
    setIsQuestionFour(!isQuestionFour);
  };
  const toggleQuestionFiveCollapse = () => {
    setIsQuestionFive(!isQuestionFive);
  };
  const toggleQuestionSixCollapse = () => {
    setIsQuestionSix(!isQuestionSix);
  };

  return (
    <>
      {" "}
      <Meta
        title={
          "EMotorad | Buy Electric Cycle & E-Bike Online | Best E Bicycle & E Bike"
        }
        description={
          "EMotorad offers a diverse range of premium electric bicycles and e-bikes. Shop now for the best deals on e-cycle & e-bike."
        }
        cannonicalTag={"https://www.emotorad.com/"}
      />
      <>
        <Navbar />
        <CallBtn bottom={"20%"} />
        <WhatsAppBot bottom={"12%"} />
        <TestRide />
        <div className="position-relative w-100" style={{ margin: "auto" }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal-video"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                zIndex: "100",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                position: "static",
                margin: "auto",
                border: "none",
                padding: "0px",
                outline: "none",
                overflow: "hidden",
              },
            }}
            contentLabel="Video Modal"
          >
            <ReactPlayer
              url="https://youtu.be/6V61TXIIdGk?feature=shared"
              controls={true}
              playing={true}
              width="100%"
              height="100%"
            />
          </Modal>
        </div>

        {/* hero banner */}
        <div className="blogs-card pt-0 ps-0 pe-0">
          <Splide
            className=" p-0 align-items-center"
            options={{
              arrows: true,
              drag: true,
              type: "loop",
              pagination: true,
              autoplay: true,
              perMove: 1,
              gap: "2em",
              padding: "0em",
              pauseOnHover: false,
              resetProgress: false,
              interval: 5000,
              perPage: 1,
              speed: 1000,
              breakpoints: {
                700: {
                  perPage: 1,
                  arrows: false,
                  pagination: true,
                  autoplay: false,
                  perMove: 1,
                  interval: 5000,
                  gap: "0em",
                  padding: "0em",
                  pauseOnHover: false,
                  resetProgress: false,
                  focus: 0,
                  start: 0,
                  speed: 500,
                },
              },
            }}
            muted={true}
          >
            <SplideSlide>
              <div className="position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+Prebook/new+trex+mobile+banner.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+Prebook/new+trex+homepage+banner.jpg"
                  />
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/t-rex+Prebook/new+trex+homepage+banner.jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>
                <div className="position-absolute new-banner-cta d-none d-lg-block">
                  <Link
                    to={"/bikes/t-rex+"}
                    className="home-cta-1 text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
                <div className="position-absolute new-banner-cta-right d-lg-none">
                  <Link
                    to={"/bikes/t-rex+"}
                    className="home-cta text-center text-decoration-none"
                    style={{
                      padding: "1em 2.5em",
                      fontFamily: "Metropolis-bold",
                      fontSize: "0.8rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Artboard+2+(1).jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
                  />
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>
                <div className="position-absolute new-banner-cta-right-1 d-none d-lg-block">
                  <Link
                    to={"/bikes/x1-pro-legend-07"}
                    className="home-cta-1 text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
                <div className="position-absolute new-banner-cta-right d-lg-none">
                  <Link
                    to={"/bikes/x1-pro-legend-07"}
                    className="home-cta text-center text-decoration-none"
                    style={{
                      padding: "1em 2.5em",
                      fontFamily: "Metropolis-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    BUY NOW
                  </Link>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Home+Page+Banner+(Mobile).jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Home+Page+Banner+(Desktop).jpg"
                  />
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Home+Page+Banner+(Desktop).jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>
                <div className="position-absolute new-banner-cta-right d-none d-lg-block">
                  <Link
                    to={"/bikes"}
                    className="home-cta text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
                    style={{
                      fontFamily: "Metropolis-bold",
                      fontSize: "1.1rem",
                    }}
                  >
                    EXPLORE NOW
                  </Link>
                </div>
                <div className="position-absolute new-banner-cta-right d-lg-none">
                  <Link
                    to={"/bikes"}
                    className="home-cta text-center text-decoration-none"
                    style={{
                      padding: "1em 2.5em",
                      fontFamily: "Metropolis-bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    EXPLORE NOW
                  </Link>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/mobile+banne+02+final.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/web+banner+2+final.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/web+banner+2+final.jpg"
                    alt="Banner1"
                    className="d-block w-100"
                  />
                </picture>

                <div className="play-icon-container" onClick={openModal}>
                  {window.innerWidth > 700 ? (
                    <Icon icon="logos:youtube-icon" width={80} />
                  ) : (
                    <Icon icon="logos:youtube-icon" width={40} />
                  )}
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* ......Bike Cards Slider..... */}
        <div className="mt-4 ps-3 ps-lg-5 pe-lg-5 ">
          <div className="bikes-card home-bike-card">
            <h1
              className="fw-bold mb-3 text-uppercase text-em heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                // letterSpacing: "1px",
              }}
            >
              best selling e-cycles
            </h1>
            <Swiper
              spaceBetween={15}
              slidesPerView={1.2}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              slide
              breakpoints={{
                1024: {
                  initialSlide: 4,
                  allowTouchMove: false,
                  slidesPerView: 4,
                  spaceBetween: 20,
                  loop: false,
                  slide: false,
                  noSwiping: true,
                },
              }}
            >
              {bikes &&
                bikes
                  .filter(
                    (item) =>
                      item.name == "X1" ||
                      item.name == "X2" ||
                      item.name == "T-Rex Air" ||
                      item.name == "EMX+"
                  )
                  .map((item, index) => (
                    <SwiperSlide key={index} className="p-1 ">
                      <div
                        className="border position-relative rounded-3 p-2"
                        style={{
                          boxShadow:
                            "0px 0px 5px 5px rgba(0, 96, 214, 0.3), -0px -0px 1px 1px rgba(0, 96, 214, 0.3)",
                          overflow: "hidden",
                        }}
                      >
                        <h5
                          className="text-center text-uppercase m-0"
                          style={{
                            fontSize: "1.2rem",
                            fontFamily: "Metropolis-bold",
                          }}
                        >
                          {item.subCategory} range
                        </h5>
                        <Link
                          to={`/bikes/${item.urlName}`}
                          style={{ color: "inherit" }}
                          className="text-decoration-none"
                        >
                          <div>
                            <LazyLoadImage
                              src={item.cartImages[modelStates[index]]}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </Link>

                        <div className="ps-2  pe-2">
                          <div
                            className="d-flex mb-2 justify-content-center"
                            style={{ gap: "0.5em" }}
                          >
                            {item.models.map((model, colorIndex) => (
                              <button
                                key={colorIndex}
                                className={`rounded-circle home-bike-card-color border-0 ${
                                  modelStates[index] === colorIndex
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  pointer: "cursor",
                                  minWidth: "30px",
                                  minHeight: "30px",
                                  background: model.colorCode,
                                }}
                                onClick={() =>
                                  handleColorClick(index, colorIndex)
                                }
                              ></button>
                            ))}
                          </div>
                          <Link
                            to={`/bikes/${item.urlName}`}
                            style={{ color: "inherit" }}
                            className="text-decoration-none"
                          >
                            {" "}
                            <div className="mt-4 d-flex justify-content-between">
                              <h3
                                className="w-50 m-0 mb-2"
                                style={{
                                  fontSize: "1.6rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontWeight: "600",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {item.name === "X1 Limited Edition" ? (
                                  <>
                                    X1{" "}
                                    <span
                                      style={{
                                        fontSize: "0.6em",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Limited Edition
                                    </span>
                                  </>
                                ) : (
                                  item.name
                                )}
                              </h3>
                              <div>
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-rate"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                                <Icon
                                  icon="ic:round-star-half"
                                  style={{ color: "#ffce31" }}
                                  width={"20"}
                                />
                              </div>
                            </div>
                            <p
                              className="m-0 mb-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.7)",
                                fontSize: "0.8em",
                                display: "-webkit-box",
                                maxWidth: "100%",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                              }}
                            >
                              {item.cardDesc}
                            </p>
                            <p
                              style={{
                                fontSize: "1.5em",
                              }}
                            >
                              Starting at{" "}
                              <b style={{ fontFamily: "Metropolis-bold" }}>
                                {currencyFormat(item.price)}
                              </b>{" "}
                            </p>
                          </Link>

                          <div className="d-flex mb-3">
                            <div className="w-50 pe-1">
                              <Link
                                to={"/testride"}
                                style={{
                                  fontSize: "0.7em",
                                }}
                                className="btn w-100 text-uppercase fw-bold text-decoration-none em-btn"
                              >
                                test ride
                              </Link>
                            </div>
                            <div className="w-50 ps-1">
                              <Link
                                to={`/bikes/${item.urlName}`}
                                style={{
                                  fontSize: "0.7em",
                                }}
                                className="btn border-0 w-100 text-uppercase text-decoration-none fw-bold know-btn"
                              >
                                Know More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </div>
        </div>

        {/* range card */}
        <div
          className="range-section mt-5 mb-5 pt-3 d-lg-none"
          style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)" }}
        >
          <div className="ps-lg-5 pe-lg-5 ps-3 pb-3 pb-lg-0">
            <h1
              className="text-uppercase text-em mb-3 heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                fontWeight: "900",
              }}
            >
              Premium E-cycle Range{" "}
            </h1>
            <Swiper
              spaceBetween={15}
              slidesPerView={1.15}
              initialSlide={2}
              centeredSlides={false}
              loop={true}
              slide
              className="d-lg-none"
              onSlideChange={handleSlideChange}
            >
              {rangeData.map((item, index) => (
                <SwiperSlide key={index} className="p-1">
                  <div
                    className="border position-relative rounded-3 p-2"
                    style={{
                      boxShadow: "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                      overflow: "hidden",
                    }}
                  >
                    <h5
                      className="text-center fw-bold text-uppercase m-0"
                      style={{
                        fontSize: "1rem",
                        fontFamily: "Metropolis-bolder",
                      }}
                    >
                      {item.name} range
                    </h5>
                    <Link
                      to={`/bikes#${item.name}`}
                      style={{ color: "inherit" }}
                      className="text-decoration-none"
                    >
                      <div>
                        <LazyLoadImage
                          src={item.url2}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="text-center mt-4">
              {activeSwiper === 4 ? (
                <Link
                  to={"/bikes/range/x-factor"}
                  className="em-btn btn p-2 text-center text-uppercase text-decoration-none"
                  style={{ fontSize: "0.8em" }}
                >
                  EXPLORE x-factor range
                </Link>
              ) : (
                <Link
                  to={"/bikes/range/desire"}
                  className="em-btn btn p-2 text-center text-uppercase text-decoration-none"
                  style={{ fontSize: "0.8em" }}
                >
                  EXPLORE desire range
                </Link>
              )}
            </div>
            <div className=" col-12 d-none">
              <div className="d-flex" style={{ gap: "1em" }}>
                {rangeData.map((item) => (
                  <div
                    className="card-doodle"
                    onMouseOver={() => handleHover(item.id)}
                    key={item.id}
                    style={{
                      maxWidth: activeItem === item.id ? "70%" : "15%",
                      flexGrow: activeItem === item.id ? 4 : 1,
                      // backgroundImage: `url(${item.url1})`,
                      boxShadow:
                        activeItem === item.id
                          ? "0px 0px 10px rgba(0, 77, 172)"
                          : "0px 0px 20px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div className="row">
                      <div className="col-6"></div>
                      <div className="col-6">
                        <img src={item.url1} className="w-50"></img>
                      </div>
                    </div>

                    {activeItem !== item.id && (
                      <div
                        style={{
                          position: "absolute",
                          top: "0%",
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "black",
                          background:
                            "linear-gradient(to bottom, rgba(255,255,255,0), black)",
                        }}
                      />
                    )}
                    {activeItem !== item.id && (
                      <div className="w-100">
                        <p
                          className="position-absolute text-uppercase text-light d-none d-lg-block"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Drag here
                        </p>
                        <p
                          className="position-absolute text-uppercase text-light d-lg-none"
                          style={{
                            bottom: "10%",
                            right: "5%",
                            transform: "rotate(270deg)",
                          }}
                        >
                          Click here
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* breaker */}
        <div>
          <picture className="main-banner-img mt-3 mt-lg-5 ">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/breaker/Home+Page+Breaker+(Mobile).jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/breaker/Home+Page+Breaker+(Desktop).jpg"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/breaker/Home+Page+Breaker+(Desktop).jpg"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
        </div>

        {/* gif section */}
        <div className=" w-100 mt-5 mt-lg-5 ">
          <div className="ps-lg-5 pe-lg-5 ps-3">
            <h1
              className="fw-bold mb-3 text-uppercase text-em heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
              }}
            >
              smart features, smart rides
            </h1>
          </div>

          <div className="row m-0">
            <div className="col-lg-8 col-12 p-0 mb-lg-0 mb-2">
              <div className="position-relative">
                {window.innerWidth <= 650 ? (
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="w-100"
                  >
                    <source
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Desktop(Battery).mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <LazyLoadImage
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Battery).gif"
                    alt="Banner1"
                    className="w-100"
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "black",
                    background:
                      "linear-gradient(to bottom, transparent, black)",
                  }}
                />
                <div className="position-absolute desk-gif-1">
                  <h2
                    className="text-uppercase text-light m-0 mobile-gif-heading"
                    style={{
                      fontFamily: "Metropolis-bolder",
                      fontWeight: "900",
                    }}
                  >
                    Charge anywhere
                  </h2>
                  <p className="text-light m-0 mb-2 gif-content">
                    Like your phone, plug it in, and zip-zap-zoom!
                  </p>
                  <a
                    href="https://www.emotorad.com/blog/clj2wz1fu2p6y0apiys2ntrv1"
                    className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                  >
                    Know More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 p-0 ps-lg-3">
              <div className="d-flex d-lg-block " style={{ gap: "0.5em" }}>
                <div className="position-relative mb-0 mb-lg-3">
                  {window.innerWidth <= 650 ? (
                    <video
                      autoPlay
                      muted
                      loop
                      disableRemotePlayback
                      playsInline
                      className="w-100"
                    >
                      <source
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Mobile(Motor).mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <LazyLoadImage
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(Motor).gif"
                      alt="Banner1"
                      className="w-100"
                    />
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div className="position-absolute desk-gif-2 pe-2">
                    <h3
                      className="text-uppercase text-light m-0 mobile-gif-heading"
                      style={{
                        fontFamily: "Metropolis-bolder",
                        fontWeight: "900",
                      }}
                    >
                      SWEAT BY CHOICE
                    </h3>
                    <p className="text-light m-0 mb-2 gif-content d-none d-lg-block">
                      Pedal or accelerate, either way, we got your back
                    </p>
                    <p className="text-light m-0 mb-2 gif-content d-lg-none">
                      Pedal or accelerate, we got you
                    </p>
                    <a
                      href="https://www.emotorad.com/blog/clsuelnqq1axh0bmr78wkfnfh"
                      className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                    >
                      Know More
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <picture>
                    <source
                      media="(max-width:650px)"
                      srcSet={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/display-gif-mobile.gif"
                      }
                    />
                    <source
                      media="(min-width:651px)"
                      srcSet={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                      }
                    />
                    <LazyLoadImage
                      src={
                        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Desktop(display).gif"
                      }
                      alt="Banner1"
                      className="w-100"
                    />
                  </picture>
                  <div
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                      background:
                        "linear-gradient(to bottom, transparent, black)",
                    }}
                  />
                  <div className="position-absolute desk-gif-3 pe-2">
                    <h3
                      className="text-uppercase text-light m-0 mobile-gif-heading"
                      style={{
                        fontFamily: "Metropolis-bolder",
                        fontWeight: "900",
                      }}
                    >
                      Lifetime Warranty*
                    </h3>
                    <p className="text-light m-0 mb-2 gif-content d-lg-block d-none">
                      Rest assured we'll take care of you, or your bike at
                      least!
                    </p>
                    <p className="text-light m-0 mb-2 gif-content d-lg-none">
                      We will take care of you
                    </p>
                    <a
                      href="https://www.emotorad.com/blog/clj2wz1fu2p6y0apiys2ntrv1"
                      className="em-btn btn p-2 text-center text-decoration-none gif-btn"
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modes */}
        <div className="mt-5 ps-lg-5 pe-lg-5 ps-3 mb-1">
          <h1
            className="text-em mb-1 fw-bold text-uppercase heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              fontWeight: "900",
            }}
          >
            A mode for every mood{" "}
          </h1>
          <div className="blogs-card container-lg pt-2 ps-lg-5  pb-lg-5 mb-2 mb-lg-0 pe-lg-5 ps-0 pe-0">
            <Swiper
              spaceBetween={14}
              slidesPerView={1.2}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1  h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/thrttle-1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/thrttle-1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      ACCELERATE
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "87%" }}>
                        Twist the throttle and zoom through without pedalling
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1   h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/PAS_1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/PAS_1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      PEDAL ASSIST
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "75%" }}>
                        Your pedal power meets our electric magic
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1   h-100 rounded-3">
                <div
                  className=" rounded-3 p-2 h-100"
                  style={{ boxShadow: "0px 0px 5px rgba(0,0,0,0.40)" }}
                >
                  <div className=" rounded-3 p-2">
                    <div>
                      {window.innerWidth <= 650 ? (
                        <video
                          autoPlay
                          muted
                          loop
                          disableRemotePlayback
                          playsInline
                          className="rounded-3 img-fluid"
                        >
                          <source
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/gifImages/Mechanical_1_1.mp4"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <LazyLoadImage
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/gifImages/Mechanical_1_1.gif"
                          alt="Banner1"
                          className="img-fluid rounded-3"
                        />
                      )}
                    </div>

                    <h4
                      className="fw-bold ps-1 mt-3 mb-1"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      MECHANICAL
                    </h4>
                    <div className="d-flex ps-1 justify-content-between">
                      <span style={{ fontSize: "0.9rem", width: "85%" }}>
                        Embrace nostalgia and go old school with pedal mode
                      </span>
                      <a
                        href="https://www.emotorad.com/blogs/modes-of%20electric-cycles"
                        className="text-decoration-none em-hover"
                      >
                        <Icon icon="mynaui:arrow-long-right" width={"30"} />
                      </a>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* celebs */}
        <div
          className=" position-relative "
          style={{
            minHeight: "400px",
          }}
        >
          <div className="mt-4  mb-3 ps-lg-5 pe-lg-5 ps-3 pe-3">
            <h1
              className="text-em fw-bold text-uppercase heading-homepage"
              style={{
                fontFamily: "Metropolis-black",
                fontWeight: "900",
              }}
            >
              E-cycles caught posing!{" "}
            </h1>
          </div>

          <div
            className="position-relative"
            style={{
              backgroundImage:
                'url("https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/ABout%2BUs.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "350px",
            }}
          >
            <div
              className="position-absolute h-100 w-100"
              style={{ background: "rgba(0,0,0,0.6)" }}
            ></div>
            <h3
              className="position-absolute text-light w-100 text-center"
              style={{ fontFamily: "Metropolis-bolder", top: "30%" }}
            >
              “MOST PROMISING EV OF THE YEAR”{" "}
            </h3>{" "}
            <p
              className="text-light mt-1 w-100 text-center celebs-heading text-uppercase"
              style={{ fontSize: "0.9em" }}
            >
              That’s what they said
            </p>
          </div>
          <div className="ps-3" style={{ marginTop: "-5em" }}>
            <Swiper
              spaceBetween={5}
              slidesPerView={1.3}
              autoplay={true}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 6.2,
                  spaceBetween: 20,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Bhaichung.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Sonali.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Isha.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Jayant.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Nitin.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Shaan.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Suniel.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Terence.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Neha+Dhupia.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 rounded-3">
                <LazyLoadImage
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/homepage/clebs/Angad+Bedi.jpg"
                  alt=""
                  className="img-fluid"
                />
              </SwiperSlide>{" "}
            </Swiper>
          </div>
        </div>
        {/* faqs */}
        <div className="mt-5 px-3 ps-lg-5 pe-lg-5">
          <h1
            className="fw-bold mb-3 text-em heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              // letterSpacing: "1px",
            }}
          >
            FAQs
          </h1>
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-expanded={isQuestionOne ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionOneCollapse}
              >
                <h2 className="faq-heading text-em">
                  1. Which is the best electric cycle to buy?
                </h2>
                <span className="iconify">
                  {isQuestionOne ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-2 question-content" id="collapse1">
                <p className="ps-4">
                  The best electric cycle for you depends on your specific
                  needs, usage, and experience level. We recommend our X-factor
                  series for beginners, while more advanced riders might prefer
                  our Desire range. The Desire range comes equipped with unique
                  features like foldable, fat tyred e-cycles (Doodle) for
                  compact transport, or dual suspension for heavy terrains
                  (EMX+), or a long-lasting, powerful battery for longer
                  commutes (T-rex Air).
                </p>
                <p className="ps-4">
                  You can always contact our team to guide you through the right
                  e-bike fit for your needs.
                </p>

                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionOne && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-expanded={isQuestionTwo ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionTwoCollapse}
              >
                <h2 className="faq-heading text-em">
                  2. What's the range and speed?
                </h2>
                <span className="iconify">
                  {isQuestionTwo ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-2 question-content" id="collapse2">
                <p className="ps-4">
                  Our e-cycles offer an average range of 40-70 km per charge
                  when using pedal assist mode depending on the model, rider
                  weight and road conditions. The top speed is capped at 25
                  km/h.
                </p>
                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionTwo && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded={isQuestionThree ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionThreeCollapse}
              >
                <h2 className="faq-heading text-em">
                  3. What are the different riding modes of an e-cycle?
                </h2>
                <span className="iconify">
                  {isQuestionThree ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-2 question-content" id="collapse3">
                <p className="ps-4">
                  Our e-cycles come with five riding modes:
                </p>
                <div className="ps-4">
                  <ol className="ps-4 px-3">
                    <li>
                      <b>Throttle Mode: </b>
                      This mode allows you to ride without pedalling, using a
                      throttle on the handle bar.
                    </li>
                    <li>
                      <b>Pedal Assist Mode: </b>This mode offers 3-5 levels of
                      pedal assistance, letting you choose the intensity of your
                      ride.
                    </li>
                    <li>
                      <b>Pedal Mode: </b>This mode functions like a regular
                      bicycle without any electric assistance
                    </li>
                    <li>
                      <b>Walk Mode: </b>This allows you to walk at your pace
                      without lagging the weight of your cycle.
                    </li>
                    <li>
                      <b>Cruise Mode: </b>Move at a constant speed without
                      pedalling or acceleration.
                    </li>
                  </ol>
                </div>

                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionThree && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse4"
                aria-expanded={isQuestionFour ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionFourCollapse}
              >
                <h2 className="faq-heading text-em">
                  4. Do you need a licence to ride an e-cycle?
                </h2>
                <span className="iconify">
                  {isQuestionFour ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-2 question-content" id="collapse4">
                <p className="ps-4">
                  No, you do not need a license to ride an e-cycle.
                </p>
                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionFour && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse5"
                aria-expanded={isQuestionFive ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionFiveCollapse}
              >
                <h2 className="faq-heading text-em">
                  5. Do electric cycles need insurance?
                </h2>
                <span className="iconify">
                  {isQuestionOne ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-2 question-content" id="collapse5">
                <p className="ps-4">
                  While not mandatory, it is highly recommended that you insure
                  your e-cycle. EMotorad has partnered with Global Assure to
                  offer a comprehensive e-cycle insurance policy.
                </p>
                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionFive && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
          <div>
            <div>
              <div
                className="d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse"
                data-bs-target="#collapse6"
                aria-expanded={isQuestionSix ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={toggleQuestionSixCollapse}
              >
                <h2 className="faq-heading text-em">
                  6. What are the precautions and accessories you need for an
                  e-cycle?
                </h2>
                <span className="iconify">
                  {isQuestionSix ? (
                    <i className="fa-solid fa-minus"></i>
                  ) : (
                    <i className="fa-solid fa-plus"></i>
                  )}
                </span>
              </div>

              <div className="collapse mt-3 question-content" id="collapse6">
                <p className="ps-4">
                  Always wear a helmet and appropriate gear. Ensure your e-cycle
                  is equipped with reflective lights to enhance visibility and
                  safety when riding at night.
                </p>
                <hr className="p-0" style={{ marginTop: "14px" }} />
              </div>
            </div>
          </div>
          {!isQuestionSix && (
            <hr className="p-0" style={{ marginTop: "16px" }} />
          )}
        </div>

        {/* .....Blogs...... */}
        <div className="mt-5 ps-lg-5 pe-lg-5 ps-3 pe-3 mb-lg-0 mb-3">
          <h1
            className="text-uppercase text-em mb-3 heading-homepage"
            style={{
              fontFamily: "Metropolis-black",
              fontWeight: "900",
            }}
          >
            EM Knowledge hub
          </h1>
          <div className="blogs-card mt-0 pt-1  pb-lg-5 mt-lg-3 pe-0 mb-2 ps-0 p-0">
            <Swiper
              spaceBetween={15}
              slidesPerView={1.3}
              loop={true}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  noSwiping: true,
                  allowTouchMove: false,
                  loop: false,
                  slide: false,
                },
              }}
              slide
            >
              <SwiperSlide className="p-1 rounded-3">
                <div
                  className=" rounded-3 p-3 "
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/what-is-an-electric-cycle"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/cdYXwMvDQHSIXD92Pbir"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Friday, 28 April 2023
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      E-cycling for Dummies: Here’s Everything You Need to Know!
                    </h6>
                    <p
                      className="text-muted"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        fontSize: "0.9em",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Don't know what e-cycles are? Is it just a cycle? Let's
                      dive in and find out.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1  h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/anatomy-electric-cycle"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/1j6AdmqqQ6elXTLIUo0n"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Wednesday, 21 February 2024
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Anatomy of a Great Electric Cycle
                    </h6>

                    <p
                      className="text-muted"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        fontSize: "0.9em",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Discover the anatomy of an electric cycle, from the frame
                      to the motor, battery, control system, brakes, suspension,
                      terrain considerations, and lights.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>{" "}
              <SwiperSlide className="p-1 h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"blogs/common-misconceptions-about-electric-eikes"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoad>
                        <img
                          src="https://media.graphassets.com/R0iJZvUyQ4KxXAI4oDNv"
                          alt=""
                          className="img-fluid rounded-2"
                        />
                      </LazyLoad>
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Tuesday, 18 July 2023
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Common Misconceptions About Electric Cycles (and how we
                      bust them!)
                    </h6>
                    <p
                      className="text-muted "
                      style={{
                        display: "-webkit-box",
                        fontSize: "0.9em",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      Learn the real deal about electric cycles. We're busting
                      myths about speed, laziness, and more. Empower yourself
                      with facts and ride on with confidence!
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide className="p-1 h-100 rounded-3">
                <div
                  className=" rounded-3 p-3 h-100"
                  style={{ boxShadow: "1px 1px 5px lightgrey" }}
                >
                  <Link
                    to={"/blogs/electriccycle-care"}
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <div>
                      <LazyLoadImage
                        src="https://media.graphassets.com/T6q2GI93RYusdPG5DddR"
                        alt=""
                        className="img-fluid rounded-2"
                      />
                    </div>
                    <p
                      style={{ fontSize: "0.9em" }}
                      className="text-muted mb-2 mt-2"
                    >
                      Saturday, 3 February 2024
                    </p>
                    <h6
                      className="fw-bold"
                      style={{
                        display: "-webkit-box",
                        maxWidth: "100%",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "60px",
                      }}
                    >
                      Reasons Why You Should Care About Electric Cycles
                    </h6>
                    <p
                      className="text-muted "
                      style={{
                        display: "-webkit-box",
                        fontSize: "0.9em",
                        maxWidth: "100%",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: "80px",
                      }}
                    >
                      From extended range to zero emissions, explore the
                      compelling reasons why electric cycles should be on your
                      radar.
                    </p>

                    <button className="btn em-btn p-2 w-100">
                      Read More{" "}
                      <Icon icon="mynaui:arrow-long-right" width={"30"} />
                    </button>
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};

export default HomeScreen;
