import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import WhatsAppBot from "../Components/WhatsAppBot";
import { getAllAccessories } from "../Actions/bikeActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../Components/Meta";
import "../CSS/accessories.css";
import Footer from "../Components/Footer";
import CallBtn from "../Components/CallBtn";
import "@splidejs/splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import TestRide from "../Components/TestRide";
import Loder from "../Components/Loder";
import { Icon } from "@iconify/react";
const AllAccessoriesScreen = () => {
  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const dispatch = useDispatch();

  const allAccessories = useSelector((state) => state.allAccessories);
  const { accessories } = allAccessories;
  useEffect(() => {
    dispatch(getAllAccessories());
  }, []);

  return (
    <>
      {/* <Loder /> */}
      <Meta
        title={
          "Electric Cycle Accessories, Buy Accessories for Electric Bicycle & E Bike Online"
        }
        description={
          "Shop the best quality accessories for your electric bicycle include helmets, air pump, locks etc at best prices."
        }
        cannonicalTag={"https://www.emotorad.com/accessories"}
      />
      <CallBtn bottom={"20%"} />
      <WhatsAppBot bottom={"12%"} />
      <Navbar />
      <TestRide />
      <div id="your-first-div-id">
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/mobile/banners/Accesories_393x680.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/banners/allaccesories_desktop.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/banners/allaccesories_desktop.jpg"
            className="img-fluid w-100"
            alt=""
            srcSet=""
          />
        </picture>
      </div>
      <h1
        className="mt-4 text-center"
        style={{ fontFamily: "Metropolis-medium" }}
      >
        Electric Cycle Accessories Online
      </h1>
      {accessories && (
        <>
          {/* <div className="container mt-5 mb-5 text-center text-lg-start accessories-range">
            <h2 className="text-passion">COMBOS</h2>
            <p className="accessories-range-sub-heading text-center text-lg-start">
              Find your perfect fit
            </p>
            <Splide
              className=" p-lg-0 p-3 pb-5 pt-0 flex align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "loop",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",

                pauseOnHover: false,
                resetProgress: false,
                interval: 1000,

                perPage: 3,

                speed: 500,
                breakpoints: {
                  640: {
                    perPage: 1,
                    arrows: false,
                    pagination: true,
                    autoplay: false,
                    perMove: 1,
                    drag: true,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,
                    focus: 0,
                    perPage: 1,
                    start: 0,

                    speed: 500,
                  },
                },
              }}
            >
              {accessories &&
                accessories.map(
                  (product,i) =>
                    product.subCategory === "combo" && (
                      <SplideSlide key={i} className=" ">
                        <div className=" accessories-range-card  mt-2  p-md-3  ">
                          <Link
                            to={`/accessories/${product.urlName}`}
                            className="text-decoration-none home-cta-2 text-center  w-100 mb-2 text-dark"
                          >
                            <div className=" p-lg-5 p-lg-2 p-2  acc-card">
                              <img
                                src={product.cartImages[0]}
                                alt=""
                                className="img-fluid d-block mx-auto "
                              />
                            </div>
                            <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                              <p className="acc-name">{product.name}</p>
                              <hr />
                      <div
                    className="d-flex mt-lg-4 mt-3 mb-lg-4 mb-3 product-card-price"
                    style={{ gap: "1em" }}
                  >
                    <p className="bike-price">
                      {currencyFormat(product.price)}
                    </p>
                    {product.slashPrice > 0 && (
                      <p className="slash-bike-price">
                        {currencyFormat(product.slashPrice)}
                      </p>
                    )}
                    {product.slashPrice > 0 && (
                      <span className="save-percent-product-screen">
                        save &nbsp;
                        {Math.trunc(
                          ((product.slashPrice - product.price) /
                            product.slashPrice) *
                            100
                        )}
                        {"%"}
                      </span>
                    )}
                    
                  </div>

                    <Link
                      className="text-decoration-none home-cta-2 text-center  w-100 mb-2"
                      to={`/accessories/${product.urlName}`}
                    >
                      <button
                      className="home-cta-2 w-100 mb-2"
                        style={{
                          borderRadius: "25px",
                        }}
                      >
                        Buy Now
                      </button>
                    </Link>
                            </div>
                          </Link>
                        </div>
                      </SplideSlide>
                    )
                )}
            </Splide>
          </div> */}
          <div className="container mt-5 pt-lg-5 mb-5 text-center text-lg-start accessories-range">
            <div className="row">
              <div className="col-12 col-lg-5 pt-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Safety/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 ps-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  HELMETS
                </h1>
                <p className="accessories-range-sub-heading text-center text-lg-start">
                  Because you need VIP protection
                </p>
                <Splide
                  className=" p-lg-0 p-3 pb-5 pt-0 flex align-items-center"
                  options={{
                    arrows: false,
                    drag: false,
                    type: "loop",
                    pagination: false,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,

                    perPage: 2,

                    speed: 500,
                    breakpoints: {
                      640: {
                        perPage: 1,
                        arrows: false,
                        pagination: true,
                        autoplay: false,
                        perMove: 1,
                        drag: true,
                        gap: "2em",

                        pauseOnHover: false,
                        resetProgress: false,
                        interval: 1000,
                        focus: 0,
                        perPage: 1,
                        start: 0,

                        speed: 500,
                      },
                    },
                  }}
                >
                  {accessories &&
                    accessories.map(
                      (product, i) =>
                        product.subCategory === "helmet" && (
                          <SplideSlide key={i} className=" ">
                            <div className=" accessories-range-card  mt-2  p-md-3  ">
                              <Link
                                style={{ color: "inherit" }}
                                className="text-decoration-none "
                                to={`/accessories/${product.urlName}`}
                              >
                                <div className="mb-4 mt-4  acc-card">
                                  <img
                                    src={product.cartImages[0]}
                                    alt=""
                                    className="img-fluid d-block mx-auto "
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                                  <h3 className="acc-name fw-bold">
                                    {product.name}
                                  </h3>
                                  <div
                                    className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                                    style={{ gap: "1em" }}
                                  >
                                    <p
                                      className="bike-price"
                                      style={{ fontSize: "1.2em" }}
                                    >
                                      {currencyFormat(product.price)}
                                    </p>
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="slash-bike-price"
                                        style={{
                                          fontSize: "1.5em",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {currencyFormat(product.slashPrice)}
                                      </p>
                                    )}
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="save-percent-product-screen fw-bold"
                                        style={{
                                          color: "#00cf13",
                                        }}
                                      >
                                        save &nbsp;
                                        {Math.trunc(
                                          ((product.slashPrice -
                                            product.price) /
                                            product.slashPrice) *
                                            100
                                        )}
                                        {"%"}
                                      </p>
                                    )}
                                  </div>
                                  {product.inStock > 0 ? (
                                    <Link
                                      className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      Buy Now
                                    </Link>
                                  ) : (
                                    <Link
                                      style={{ pointerEvents: "none" }}
                                      className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      OUT OF STOCK
                                    </Link>
                                  )}
                                </div>
                              </Link>
                            </div>
                          </SplideSlide>
                        )
                    )}
                </Splide>
              </div>
            </div>
          </div>
          <div className="container mt-5 pt-lg-5 mb-5 text-center text-lg-end accessories-range">
            <div className="row">
              <div className="col-12 col-lg-5 d-lg-none ">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Maintainance/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 pe-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  AIR PUMPS
                </h1>
                <p className="accessories-range-sub-heading ">
                  For wheely good times
                </p>
                <Splide
                  className=" p-lg-0 p-3 pb-5 pt-0 flex align-items-center"
                  options={{
                    arrows: true,
                    drag: true,
                    type: "loop",
                    pagination: false,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,

                    perPage: 2,

                    speed: 500,
                    breakpoints: {
                      640: {
                        perPage: 1,
                        arrows: false,
                        pagination: true,
                        autoplay: false,
                        perMove: 1,
                        drag: true,
                        gap: "2em",

                        pauseOnHover: false,
                        resetProgress: false,
                        interval: 1000,
                        focus: 0,
                        start: 0,

                        speed: 500,
                      },
                    },
                  }}
                >
                  {accessories &&
                    accessories.map(
                      (product, i) =>
                        product.subCategory === "pump" && (
                          <SplideSlide key={i} className=" ">
                            <div className=" accessories-range-card  mt-2  p-md-3  ">
                              <Link
                                to={`/accessories/${product.urlName}`}
                                className="text-decoration-none mb-2 text-dark"
                              >
                                <div className="mb-4 mt-4  acc-card">
                                  <img
                                    src={product.cartImages[0]}
                                    alt=""
                                    className="img-fluid d-block mx-auto "
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                                  <h3 className="acc-name fw-bold">
                                    {product.name}
                                  </h3>
                                  <div
                                    className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                                    style={{ gap: "1em" }}
                                  >
                                    <p
                                      className="bike-price"
                                      style={{ fontSize: "1.2em" }}
                                    >
                                      {currencyFormat(product.price)}
                                    </p>
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="slash-bike-price"
                                        style={{
                                          fontSize: "1.5em",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {currencyFormat(product.slashPrice)}
                                      </p>
                                    )}
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="save-percent-product-screen fw-bold"
                                        style={{
                                          color: "#00cf13",
                                        }}
                                      >
                                        save &nbsp;
                                        {Math.trunc(
                                          ((product.slashPrice -
                                            product.price) /
                                            product.slashPrice) *
                                            100
                                        )}
                                        {"%"}
                                      </p>
                                    )}
                                  </div>
                                  {product.inStock > 0 ? (
                                    <Link
                                      className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      Buy Now
                                    </Link>
                                  ) : (
                                    <Link
                                      style={{ pointerEvents: "none" }}
                                      className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      OUT OF STOCK
                                    </Link>
                                  )}
                                </div>
                              </Link>
                            </div>
                          </SplideSlide>
                        )
                    )}
                </Splide>
              </div>
              <div className="col-12 col-lg-5 d-none d-lg-block pt-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Maintainance/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
          <div className="container mt-5 pt-lg-5 mb-5 text-center text-lg-start accessories-range">
            <div className="row">
              <div className="col-12 col-lg-5 pt-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Security/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 ps-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  PREMIUM LOCKS
                </h1>
                <p className="accessories-range-sub-heading text-center text-lg-start">
                  Time to lock and roll
                </p>
                <Splide
                  className=" p-lg-0 p-3 pb-5 pt-0 flex align-items-center"
                  options={{
                    arrows: false,
                    drag: false,
                    type: "loop",
                    pagination: false,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,

                    perPage: 2,

                    speed: 500,
                    breakpoints: {
                      640: {
                        perPage: 1,
                        arrows: false,
                        pagination: true,
                        autoplay: false,
                        perMove: 1,
                        drag: true,
                        gap: "2em",

                        pauseOnHover: false,
                        resetProgress: false,
                        interval: 1000,
                        focus: 0,
                        perPage: 1,
                        start: 0,

                        speed: 500,
                      },
                    },
                  }}
                >
                  {accessories &&
                    accessories.map(
                      (product, i) =>
                        product.subCategory === "lock" && (
                          <SplideSlide key={i} className=" ">
                            <div className=" accessories-range-card  mt-2  p-md-3  ">
                              <div className="mb-4 mt-4  acc-card">
                                <img
                                  src={product.cartImages[0]}
                                  alt=""
                                  className="img-fluid d-block mx-auto "
                                />
                              </div>
                              <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                                <h3 className="acc-name fw-bold">
                                  {product.name}
                                </h3>
                                <div
                                  className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                                  style={{ gap: "1em" }}
                                >
                                  <p
                                    className="bike-price"
                                    style={{ fontSize: "1.2em" }}
                                  >
                                    {currencyFormat(product.price)}
                                  </p>
                                  {product.slashPrice > 0 && (
                                    <p
                                      className="slash-bike-price"
                                      style={{
                                        fontSize: "1.5em",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {currencyFormat(product.slashPrice)}
                                    </p>
                                  )}
                                  {product.slashPrice > 0 && (
                                    <p
                                      className="save-percent-product-screen fw-bold"
                                      style={{
                                        color: "#00cf13",
                                      }}
                                    >
                                      save &nbsp;
                                      {Math.trunc(
                                        ((product.slashPrice - product.price) /
                                          product.slashPrice) *
                                          100
                                      )}
                                      {"%"}
                                    </p>
                                  )}
                                </div>
                                {product.inStock > 0 ? (
                                  <Link
                                    className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                    to={`/accessories/${product.urlName}`}
                                  >
                                    Buy Now
                                  </Link>
                                ) : (
                                  <Link
                                    style={{ pointerEvents: "none" }}
                                    className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                    to={`/accessories/${product.urlName}`}
                                  >
                                    OUT OF STOCK
                                  </Link>
                                )}
                              </div>
                            </div>
                          </SplideSlide>
                        )
                    )}
                </Splide>
              </div>
            </div>
          </div>
          <div className="container mt-5 pt-lg-5 mb-5 text-center text-lg-end accessories-range">
            <div className="row">
              <div className="col-12 col-lg-5 d-lg-none pt-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Commute/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 pe-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  BACKPACK
                </h1>
                <p className="accessories-range-sub-heading ">
                  Even your tech deserves a snug hug
                </p>

                {accessories &&
                  accessories.map(
                    (product, i) =>
                      product.subCategory === "bag" && (
                        <div
                          key={i}
                          className=" bag  accessories-range-card backpack-card w-50 h-80 mt-2  p-md-3"
                        >
                          <div className="mb-4 mt-4  acc-card">
                            <img
                              src={product.cartImages[0]}
                              alt=""
                              className="img-fluid d-block mx-auto"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                            <h3 className="acc-name fw-bold">{product.name}</h3>
                            <div
                              className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                              style={{ gap: "1em" }}
                            >
                              <p
                                className="bike-price"
                                style={{ fontSize: "1.2em" }}
                              >
                                {currencyFormat(product.price)}
                              </p>
                              {product.slashPrice > 0 && (
                                <p
                                  className="slash-bike-price"
                                  style={{
                                    fontSize: "1.5em",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {currencyFormat(product.slashPrice)}
                                </p>
                              )}
                              {product.slashPrice > 0 && (
                                <p
                                  className="save-percent-product-screen fw-bold"
                                  style={{
                                    color: "#00cf13",
                                  }}
                                >
                                  save &nbsp;
                                  {Math.trunc(
                                    ((product.slashPrice - product.price) /
                                      product.slashPrice) *
                                      100
                                  )}
                                  {"%"}
                                </p>
                              )}
                            </div>
                            {product.inStock > 0 ? (
                              <Link
                                className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                to={`/accessories/${product.urlName}`}
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <Link
                                style={{ pointerEvents: "none" }}
                                className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                to={`/accessories/${product.urlName}`}
                              >
                                OUT OF STOCK
                              </Link>
                            )}
                          </div>
                        </div>
                      )
                  )}
              </div>
              <div className="col-12 col-lg-5 d-none d-lg-block pt-2">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Commute/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
          <div className="container  mt-5 pt-lg-5 mb-5 text-center text-lg-end accessories-range">
            <div className="w-100 d-flex justify-content-center align-content-center holderDiv ">
              <div className="col-12 col-lg-5 d-lg-block">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Rivet+Mobile+holder.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 ps-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage text-lg-start"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  360 DEGREE PHONE MOUNT
                </h1>
                <p className="accessories-range-sub-heading text-center text-lg-start">
                  E-Biking meets phoning without the oops
                </p>
                {accessories &&
                  accessories.map(
                    (product, i) =>
                      product.subCategory === "holder" && (
                        <div
                          key={i}
                          className="w-md-75 holder accessories-range-card w-50 h-80 mount-card  mt-2  p-md-3  "
                        >
                          <div className="mb-4 mt-4  acc-card">
                            <img
                              src={product.cartImages[0]}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                          <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                            <h3 className="acc-name fw-bold">{product.name}</h3>
                            <div
                              className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                              style={{ gap: "1em" }}
                            >
                              <p
                                className="bike-price"
                                style={{ fontSize: "1.2em" }}
                              >
                                {currencyFormat(product.price)}
                              </p>
                              {product.slashPrice > 0 && (
                                <p
                                  className="slash-bike-price"
                                  style={{
                                    fontSize: "1.5em",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {currencyFormat(product.slashPrice)}
                                </p>
                              )}
                              {product.slashPrice > 0 && (
                                <p
                                  className="save-percent-product-screen fw-bold"
                                  style={{
                                    color: "#00cf13",
                                  }}
                                >
                                  save &nbsp;
                                  {Math.trunc(
                                    ((product.slashPrice - product.price) /
                                      product.slashPrice) *
                                      100
                                  )}
                                  {"%"}
                                </p>
                              )}
                            </div>
                            {product.inStock > 0 ? (
                              <Link
                                className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                to={`/accessories/${product.urlName}`}
                              >
                                Buy Now
                              </Link>
                            ) : (
                              <Link
                                style={{ pointerEvents: "none" }}
                                className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                to={`/accessories/${product.urlName}`}
                              >
                                OUT OF STOCK
                              </Link>
                            )}
                          </div>
                        </div>
                      )
                  )}
              </div>
              {/* <div className="col-12 col-lg-5 d-none d-lg-block">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Commute/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div> */}
            </div>
          </div>
          <div className="container mt-5 pt-lg-5 mb-5 text-center text-lg-end accessories-range">
            <div className="row">
              <div className="col-12 col-lg-5 pt-2 d-lg-none">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Add+ons/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-12 pe-lg-5  mt-4 mt-lg-0 col-lg-7">
                <h1
                  className="heading-homepage "
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  HEAD LIGHTS
                </h1>
                <p className="accessories-range-sub-heading">
                  It's the time to disco
                </p>
                <Splide
                  className=" p-lg-0 p-3 pb-5 pt-0 flex align-items-center"
                  options={{
                    arrows: true,
                    drag: true,
                    type: "loop",
                    pagination: false,
                    autoplay: false,
                    perMove: 1,
                    gap: "2em",

                    pauseOnHover: false,
                    resetProgress: false,
                    interval: 1000,

                    perPage: 2,

                    speed: 500,
                    breakpoints: {
                      640: {
                        perPage: 1,
                        arrows: false,
                        pagination: true,
                        autoplay: false,
                        perMove: 1,
                        drag: true,
                        gap: "2em",

                        pauseOnHover: false,
                        resetProgress: false,
                        interval: 1000,
                        focus: 0,
                        start: 0,

                        speed: 500,
                      },
                    },
                  }}
                >
                  {accessories &&
                    accessories
                      .slice(0)
                      .reverse()
                      .map(
                        (product, i) =>
                          product.subCategory === "headlight" && (
                            <SplideSlide key={i} className=" ">
                              <div className=" accessories-range-card  mt-2  p-md-3  ">
                                <div className="mb-4 mt-4  acc-card">
                                  <img
                                    src={product.cartImages[0]}
                                    alt=""
                                    className="img-fluid d-block mx-auto "
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-center ps-2 pe-2 justify-content-between">
                                  <h3 className="acc-name fw-bold">
                                    {product.name}
                                  </h3>
                                  <div
                                    className="d-flex mt-lg-2 mt-1 mb-lg-2 mb-1 product-card-price align-items-center justify-content-center"
                                    style={{ gap: "1em" }}
                                  >
                                    <p
                                      className="bike-price"
                                      style={{ fontSize: "1.2em" }}
                                    >
                                      {currencyFormat(product.price)}
                                    </p>
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="slash-bike-price"
                                        style={{
                                          fontSize: "1.5em",
                                          textDecoration: "line-through",
                                        }}
                                      >
                                        {currencyFormat(product.slashPrice)}
                                      </p>
                                    )}
                                    {product.slashPrice > 0 && (
                                      <p
                                        className="save-percent-product-screen fw-bold"
                                        style={{
                                          color: "#00cf13",
                                        }}
                                      >
                                        save &nbsp;
                                        {Math.trunc(
                                          ((product.slashPrice -
                                            product.price) /
                                            product.slashPrice) *
                                            100
                                        )}
                                        {"%"}
                                      </p>
                                    )}
                                  </div>
                                  {product.inStock > 0 ? (
                                    <Link
                                      className="text-decoration-none btn em-btn text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      Buy Now
                                    </Link>
                                  ) : (
                                    <Link
                                      style={{ pointerEvents: "none" }}
                                      className="text-decoration-none btn btn-disabled btn-dark text-center  w-100 mb-2"
                                      to={`/accessories/${product.urlName}`}
                                    >
                                      OUT OF STOCK
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </SplideSlide>
                          )
                      )}
                </Splide>
              </div>
              <div className="col-12 col-lg-5 pt-2 d-lg-block d-none">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-10-23/all-accessories/desktop/Add+ons/700x700+Desktop.jpg"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </>
      )}{" "}
      <Footer />
    </>
  );
};

export default AllAccessoriesScreen;
